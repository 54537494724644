import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Fragment } from 'react';

import Home from './Screens/Home/Home';
import Book from './Screens/Book/Book';
import Chat from './Screens/Chat/Chat';
import Menu from './Screens/Menu/Menu';
import Post from './Screens/Post/Post';
import Profile from './Screens/Profile/Profile';
import Login from './Screens/Auth/Login';
import Register from './Screens/Auth/Register';
import ForgotPassword from './Screens/Auth/ForgotPassword';

import Page404 from './Shared/404';
import PrivacyPolicy from './Utils/PrivacyPolicy';
import MonthlyMagazine from './Screens/Book/Magazine/MonthlyMagazine';
import SingleMagazineContent from './Screens/Book/Magazine/SingleMagazineContent';

function App() {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/book' element={<Book />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/post' element={<Post />} />
          <Route path='/profile' element={<Profile />} />

          <Route path='/btalkmagazine' element={<MonthlyMagazine />} />
          <Route
            path='/singlemagazinecontent'
            element={<SingleMagazineContent />}
          />

          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />

          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />

          {/* <Route path='*' element={<Page404 />} /> */}
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
