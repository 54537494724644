import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Home.css';

import Logo from './../../Images/logotran.png';
import baseURL from '../../Assets/common/baseUrl';
import Post from '../Post/Post';
import Navbar from '../../Utils/NavBar';

const Home = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const isMM = localStorage.getItem('isMM');
  const isDarkMode = localStorage.getItem('isDarkMode');

  const [post, setPost] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [PostLimit, setPostLimit] = useState(20);
  const [PostLoading, setPostLoading] = useState(false);

  useEffect(() => {
    fetchPostsApi(PostLimit);

    return () => {};
  }, [navigate]);

  const fetchPostsApi = async (PostLimit) => {
    try {
      const res = await fetch(`${baseURL}posts/limit/${PostLimit}/${userId}`);
      const data = await res.json();
      setPost([]);
      setPost(data);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMorePost = async (PostLimit) => {
    setPostLoading(true);
    try {
      const res = await fetch(`${baseURL}posts/limit/${PostLimit}/${userId}`);
      const data = await res.json();
      setPost([]);
      setPost(data);
      setLoading(false);
      setPostLoading(false);
      setPostLimit(PostLimit);
    } catch (error) {
      console.log(error);
      setPostLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: isDarkMode == 'true' ? '#222' : '#f0f0f0' }}>
      <Navbar screen={'Home'} />
      <div
        style={{
          marginTop: 80,
        }}
      >
        {post.map((item, key) => {
          return <Post key={key} post={item} />;
        })}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 150,
        }}
      >
        <button
          onClick={
            PostLoading
              ? null
              : () => {
                  fetchMorePost(PostLimit + 20);
                }
          }
          className='seemorebtn'
        >
          {PostLoading
            ? isMM == 'true'
              ? 'လုပ်ဆောင်နေပါသည်...'
              : 'Loading...'
            : isMM == 'true'
            ? 'နောက်ထပ် ပို့စ်များကို ကြည့်ပါ။'
            : 'See more posts.'}
        </button>
      </div>
    </div>
  );
};

export default Home;
