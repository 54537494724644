import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './MagazineCarousel.css';

import Logo from './../../../Images/logotran.png';
import imageBaseURL from '../../../Assets/common/imageBaseURL';
import baseURL from '../../../Assets/common/baseUrl';

const MagazineCarousel = (props) => {
  const navigate = useNavigate();
  const magazines = props.magazines;

  const [ShowMagazineLimit, setShowMagazineLimit] = useState(5);

  const ShowMagazineMore5 = () => {
    setShowMagazineLimit(ShowMagazineLimit + 5);
  };

  const goToSingleMagazine = (magazineId) => {
    navigate(`/btalkmagazine?magazineid=${magazineId}`);
  };

  function renderImages(magazines) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 500,
          alignItems: 'center',
          overflowX: 'auto',
        }}
      >
        {magazines.slice(0, ShowMagazineLimit + 1).map((magazine, index) => (
          <div
            key={index}
            style={{
              height: 450,
              width: 250,
              padding: 3,
              position:
                index === ShowMagazineLimit &&
                magazines.length > ShowMagazineLimit + 1
                  ? 'relative'
                  : null,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '400px',
                backgroundImage: `url(${Logo})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <button
                onClick={() => goToSingleMagazine(magazine._id)}
                className='singleMagazineImage'
              >
                <img
                  src={`${imageBaseURL}${magazine.coverImage}`}
                  style={{
                    height: '100%',
                    width: 250,
                    borderRadius: 10,
                  }}
                />
              </button>
              {index === ShowMagazineLimit &&
                magazines.length > ShowMagazineLimit + 1 && (
                  <button
                    onClick={ShowMagazineMore5}
                    className='seemoremagazinebtn'
                  >
                    <p>See More</p>
                    <p>+{magazines.length - ShowMagazineLimit}</p>
                  </button>
                )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div style={{ background: 'linear-gradient(95deg, #ffde2e, #fc3c44)' }}>
        <p
          style={{
            fontSize: 30,
            fontWeight: 'bold',
            color: '#fc3c44',
            marginTop: 0,
            paddingTop: 15,
            paddingLeft: 20,
            marginBottom: 15,
          }}
        >
          BTalk Magazine
        </p>
        {renderImages(magazines)}
      </div>
    </div>
  );
};

export default MagazineCarousel;
