import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from './../../Images/logotran.png';
import imageBaseURL from '../../Assets/common/imageBaseURL';
import baseURL from '../../Assets/common/baseUrl';
import MagazineCarousel from './Magazine/MagazineCarousel';
import Navbar from '../../Utils/NavBar';

const Book = () => {
  const [Loading, setLoading] = useState(true);
  const [UserData, setUserData] = useState(null);
  const [Magazines, setMagazines] = useState([]);

  const userId = localStorage.getItem('userId');
  const isDarkMode = localStorage.getItem('isDarkMode');
  const isMM = localStorage.getItem('isMM');
  const userToken = localStorage.getItem('userToken');
  const isAdmin = localStorage.getItem('isAdmin');

  const navigate = useNavigate();
  // const userId = localStorage.getItem('userId');
  useEffect(() => {
    fetchMagazineAPI();

    return () => {};
  }, [navigate]);

  const fetchMagazineAPI = async () => {
    try {
      const res = await fetch(`${baseURL}magazines`);
      const data = await res.json();
      setMagazines(data);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <div>
      <Navbar screen='Book' />
      <div
        style={{
          marginTop: 80,
        }}
      >
        {Loading ? (
          <div>
            <p>Please wait, loading...</p>
          </div>
        ) : (
          <div>
            <MagazineCarousel magazines={Magazines} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Book;
