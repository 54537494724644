import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Forgot.css';
import CustomAlertModal from '../../Utils/CustomAlertModal';

import baseURL from '../../Assets/common/baseUrl';
import imageBaseURL from '../../Assets/common/imageBaseURL';
import BlueVerifyBadge from '../../Images/BTalk_Verified_Badge.png';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem('isDarkMode');
  const isMM = localStorage.getItem('isMM');
  const userId = localStorage.getItem('userId');

  const [Email, setEmail] = useState('');
  const [UserData, setUserData] = useState();
  const [ShowContainer, setShowContainer] = useState(1);
  const [ResendEmail, setResendEmail] = useState(true);
  const [OTP, setOTP] = useState('');
  const [EnteredOtp, setEnteredOtp] = useState('');
  const [Password, setPassword] = useState('');
  const [ShowPassword, setShowPassword] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  useEffect(() => {
    if (userId === null) {
    } else if (
      (userId !== undefined && userId === 'Guest') ||
      userId.length > 4
    ) {
      navigate('/');
    }
    return () => {};
  }, [navigate, userId]);

  function validateEmail(email) {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  const handleFirstButton = async () => {
    if (validateEmail(Email) === false) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'အီးမေးလ်မှားနေပါသည်' : 'Invalid Email',
        message:
          isMM == 'true'
            ? `မိတ်ဆွေ၏ အီးမေးလ် ${Email} သည် မှားနေပါသည်။ ကျေးဇူးပြု၍ မှန်ကန်သော အီးမေးလ်လိပ်စာတစ်ခု ဖြည့်ပါ။`
            : `Your email ${Email} is an invalid email. Please enter a valid email address.`,
      });
      return;
    }
    await fetch(`${baseURL}users/getwm/${Email}`).then(async (res) => {
      if (res.status === 200) {
        const data = await res.json();
        setUserData(data);
        setShowContainer(2);
        setOTP(Math.floor(100000 + Math.random() * 900000));
      } else if (res.status === 404) {
        setModalInfo({
          isOpen: true,
          title: isMM == 'true' ? 'အကောင့် ရှာမတွေ့ပါ။' : 'Account not found!',
          message:
            isMM == 'true'
              ? 'ထိုအီးမေးလ်လိပ်စာတွင် အကောင့်မရှိပါ။ ကျေးဇူးပြု၍ အခြားအီးမေးလ်ဖြင့် ထပ်မံကြိုးစားပါ။'
              : 'There is no account with that email address. Please try again with another email.',
        });
      }
    });
  };

  const EmailData = UserData
    ? {
        name:
          isMM == 'true'
            ? ` မင်္ဂလာပါ ${UserData.name}`
            : `Hello ${UserData.name}`,
        email: UserData.email,
        subject: isMM == 'true' ? 'အတည်ပြုကုဒ်' : 'Verification code',
        message:
          isMM == 'true'
            ? `မိတ်ဆွေ၏ အတည်ပြုကုဒ်မှာ ${OTP} ဖြစ်ပါသည်။`
            : `Your verification code is ${OTP}.`,
        footermsg: isMM == 'true' ? 'လေးစားလျက်' : 'Respectfully',
      }
    : null;

  const handleSecondButton = async () => {
    fetch(`${baseURL}emails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(EmailData),
    })
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း အောင်မြင်ပါသည်'
                : 'Email sent successfully!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်အကောင့်ကို OTP ပေးပို့ပြီးပါပြီ'
                : 'OTP has been sent to your email account',
            autoClose: true,
            duration: 3000,
          });
        } else if (res.status === 500) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
                : 'Email Sending Failed!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
                : 'Something went wrong while OTP sending your email address.',
            autoClose: true,
            duration: 3000,
          });
        }
      })
      .catch((error) =>
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
              : 'Email Sending Failed!',
          message:
            isMM == 'true'
              ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
              : 'Something went wrong while OTP sending your email address.',
          autoClose: true,
          duration: 3000,
        })
      );
    setShowContainer(3);
  };

  const handleThirdButton = async () => {
    if (EnteredOtp == OTP) {
      setShowContainer(4);
      setEnteredOtp('');
      setOTP('');
    } else {
      setModalInfo({
        isOpen: true,
        title:
          isMM == 'true' ? 'မှားယွင်းသော အတည်ပြုခြင်း။' : 'Wrong Verification!',
        message:
          isMM == 'true'
            ? 'သင်ထည့်သွင်းသော OTP မှာ မှားယွင်းနေပါသည်။ ထပ်စမ်းကြည့်ပါ။'
            : 'You entered OTP is wrong. Please try again.',
        autoClose: true,
        duration: 3000,
      });

      setEnteredOtp('');
    }
  };

  const handleForthButton = async () => {
    if (Password === undefined || Password === null || Password.length < 6) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'စကားဝှက်လိုအပ်ပါသည်' : 'Password Required!',
        message:
          isMM == 'true'
            ? 'မိတ်ဆွေ၏ စကားဝှက်သည် အနည်းဆုံး အက္ခရာ ၆ လုံးရှိရမည်။'
            : `Your password must be at least 6 characters.`,
        autoClose: true,
        duration: 3000,
      });
      return;
    } else {
      await fetch(`${baseURL}users/updp/${UserData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: Password }),
      })
        .then((response) => {
          if (response.status === 200) {
            setModalInfo({
              isOpen: true,
              title: isMM == 'true' ? 'အောင်မြင်ပါသည်' : 'Success!',
              message:
                isMM == 'true'
                  ? 'စကားဝှက်ကို အောင်မြင်စွာ ပြောင်းလဲခဲ့သည်။ စကားဝှက်အသစ်ဖြင့် ပြန်လည် ဝင်ရောက်ပါ။'
                  : 'Your password was successfully changed. Please login with your new password.',
              autoClose: true,
              duration: 3000,
            });
            navigate('/login');
          } else if (response.status === 500) {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true' ? 'တစ်ခုခုမှားသွားသည်' : 'Something went wrong!',
              message:
                isMM == 'true'
                  ? 'မထင်မှတ်ထားသော အမှားဖြစ်သွားသည်။ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
                  : `Unexpected error occurred. Please try again.`,
              autoClose: true,
              duration: 3000,
            });

            return;
          }
        })
        .catch((error) => {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true' ? 'တစ်ခုခုမှားသွားသည်' : 'Something went wrong!',
            message:
              isMM == 'true'
                ? 'မထင်မှတ်ထားသော အမှားဖြစ်သွားသည်။ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
                : `Unexpected error occurred. Please try again.`,
            autoClose: true,
            duration: 3000,
          });
        });
    }
  };

  const handleGoBack = () => {
    ShowContainer > 1 ? setShowContainer(ShowContainer - 1) : navigate(-1);
  };

  const handleClose = () => {
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
  };

  const handleResendEmail = () => {
    setResendEmail(false);
    fetch(`${baseURL}emails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(EmailData),
    })
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း အောင်မြင်ပါသည်'
                : 'Email sent successfully!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်အကောင့်ကို OTP ပေးပို့ပြီးပါပြီ'
                : 'OTP has been sent to your email account',
            autoClose: true,
            duration: 3000,
          });
        } else if (res.status === 500) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
                : 'Email Sending Failed!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
                : 'Something went wrong while OTP sending your email address.',
            autoClose: true,
            duration: 3000,
          });
        }
      })
      .catch((error) => {
        setResendEmail(true);
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
              : 'Email Sending Failed!',
          message:
            isMM == 'true'
              ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
              : 'Something went wrong while OTP sending your email address.',
          autoClose: true,
          duration: 3000,
        });
      });
    setTimeout(() => {
      setResendEmail(true);
    }, 180000);
  };

  const toggleShowPassword = () => {
    setShowPassword(!ShowPassword);
  };

  return (
    <div
      style={{
        backgroundColor: isDarkMode == 'true' ? '#000' : '#fff',
        minHeight: '100vh',
      }}
      className='forgotPasswordContainer'
    >
      <CustomAlertModal
        isOpen={modalInfo.isOpen}
        onClose={handleClose}
        title={modalInfo.title}
        message={modalInfo.message}
        autoClose={modalInfo.autoClose}
        duration={modalInfo.duration}
      />
      <button onClick={handleGoBack} className='goBackContainer'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 320 512'
          width={20}
        >
          <path
            fill={isDarkMode == 'true' ? '#f0f0f0' : '#222'}
            d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
          />
        </svg>
      </button>
      {ShowContainer === 1 ? (
        <div className='forgotPasswordFirstContainer'>
          <p
            className='searchheadertxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'မိတ်ဆွေ၏ အကောင့်ကို အီးမေးလ်လိပ်စာဖြင့် ရှာဖွေပါ။'
              : 'Search your account with email address.'}
          </p>
          <input
            className='emailIpnut'
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            value={Email}
            placeholder={
              isMM == 'true' ? 'အီးမေးလ် ကိုထည့်သွင်းပါ' : 'Enter your email'
            }
          />
          <p
            className='txt1'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'မိတ်ဆွေသည် ကျွန်ုပ်တို့ထံမှ အီးမေးလ်ကို လက်ခံရရှိမည်ဖြစ်သည်။'
              : `You'll receive email from us.`}
          </p>
          <button onClick={handleFirstButton} className='btn'>
            {isMM == 'true' ? 'ရှေ့သို့' : 'Next'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 2 ? (
        <div className='forgotPasswordSecondContainer'>
          <p
            className='searchheadertxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'ဒါ မိတ်ဆွေရဲ့ အကောင့်လား'
              : 'Is that your account?'}
          </p>
          <div className='accPPNameContainer'>
            {UserData ? (
              UserData.profilePicture == '' ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={60}
                  viewBox='0 0 448 512'
                >
                  <path
                    fill='#f4b83c'
                    d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
                  />
                </svg>
              ) : (
                <img
                  className='userPPimg'
                  src={`${imageBaseURL}${UserData.profilePicture}`}
                  alt=''
                />
              )
            ) : null}
            {UserData ? <p className='userName'>{UserData.name}</p> : null}
            {UserData ? (
              UserData.verifiedUser ? (
                <img src={BlueVerifyBadge} alt='' className='BlueVerifyBadge' />
              ) : null
            ) : null}
          </div>
          <button onClick={handleSecondButton} className='btn'>
            {isMM == 'true' ? 'ရှေ့ဆက်မည်' : 'Continue'}
          </button>
          <p
            className='txt1'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'ဒါက မိတ်ဆွေရဲ့အကောင့်မဟုတ်ဘူးလား'
              : 'Is this not your account?'}
          </p>
          <button onClick={handleGoBack} className='btn'>
            {isMM == 'true' ? 'အခြားအကောင့်ကိုရှာပါ' : 'Search another account'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 3 ? (
        <div className='forgotPasswordThirdContainer'>
          <p
            className='searchheadertxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'မိတ်ဆွေ၏ အကောင့်ကိုအတည်ပြုပါ'
              : 'Verify your account'}
          </p>
          <p
            className='txt1'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {UserData.email === null
              ? isMM == 'true'
                ? `အကောင့်ကို အတည်ပြုရန် မိတ်ဆွေ၏ မိုဘိုင်းနံပါတ် ${UserData.phone} သို့ ကျွန်ုပ်တို့ ပေးပို့ထားသော OTP ကို ထည့်သွင်းပါ။`
                : `Enter OTP that we sent to your mobile number ${UserData.phone} to verify your account.`
              : isMM == 'true'
              ? `အကောင့်ကို အတည်ပြုရန် မိတ်ဆွေ၏ အီးမေးလ် ${UserData.email} သို့ ကျွန်ုပ်တို့ ပေးပို့ထားသော OTP ကို ထည့်သွင်းပါ။`
              : `Enter OTP that we sent to your email ${UserData.email} to verify your account.`}
          </p>
          <input
            className='emailIpnut'
            type='number'
            onChange={(e) => setEnteredOtp(e.target.value)}
            value={EnteredOtp}
            placeholder={
              isMM == 'true' ? 'OTP နံပါတ်ထည့်ပါ' : 'Enter OTP Number'
            }
          />

          <button
            onClick={handleThirdButton}
            style={{ marginTop: 30 }}
            className='btn'
          >
            {isMM == 'true' ? 'အတည်ပြုပါ' : 'Verify'}
          </button>
          {ResendEmail ? (
            <button
              onClick={handleResendEmail}
              style={{ marginTop: 30 }}
              className='btn'
            >
              {isMM == 'true' ? 'အီးမေးလ် ပြန်လည်ပေးပို့ပါ' : 'Resend Email'}
            </button>
          ) : null}
        </div>
      ) : null}
      {ShowContainer === 4 ? (
        <div className='forgotPasswordForthContainer'>
          <p
            className='searchheadertxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'စကားဝှက် အသစ်တစ်ခု ဖန်တီးပါ'
              : 'Create a new password'}
          </p>
          <p
            className='txt1'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'အနည်းဆုံး စာလုံး သို့မဟုတ် နံပါတ် ၆ခု ဖြင့် စကားဝှက်တစ်ခု ဖန်တီးပါ။ တခြားမခန့်မှန်းနိုင်တဲ့ အရာဖြစ်သင့်တယ်။'
              : `Create a password with at least 6 letters or numbers. It should be something other can't guess.`}
          </p>
          <div className='passContaienr'>
            <input
              className='emailIpnut'
              type={ShowPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              value={Password}
              placeholder={
                isMM == 'true'
                  ? 'စကားဝှက်အသစ်တစ်ခုဖန်တီးပါ'
                  : 'Create a new password'
              }
            />
            <button onClick={toggleShowPassword} className='showPassIconBtn'>
              {ShowPassword ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 640 512'
                  width={25}
                  className='showPassIcon'
                >
                  <path d='M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z' />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  width={25}
                  className='showPassIcon'
                >
                  <path d='M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z' />
                </svg>
              )}
            </button>
          </div>

          <button
            onClick={handleForthButton}
            style={{ marginTop: 30 }}
            className='btn'
          >
            {isMM == 'true' ? 'အတည်ပြုပါ' : 'Confirm'}
          </button>
        </div>
      ) : null}
      <button className='gotoLogin' onClick={() => navigate('/login')}>
        {isMM == 'true' ? 'လော့ဂ်အင်သို့ ပြန်သွားမည်' : 'Return to login'}
      </button>
    </div>
  );
};

export default ForgotPassword;
