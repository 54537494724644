import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import CustomAlertModal from '../../Utils/CustomAlertModal';
import ValidateEmail from '../../Utils/ValidateEmail';

import baseURL from '../../Assets/common/baseUrl';

const Register = () => {
  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem('isDarkMode');
  const isMM = localStorage.getItem('isMM');
  const userId = localStorage.getItem('userId');

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  const [ShowContainer, setShowContainer] = useState(1);
  const [Name, setName] = useState('');
  const [Birthday, setBirthday] = useState(new Date());
  const [Gender, setGender] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState(null);
  const [Password, setPassword] = useState('');
  const [ShowPassword, setShowPassword] = useState(false);
  const [Loading, setLoading] = useState('');
  const [EnteredOTP, setEnteredOTP] = useState('');
  const [ResendEmail, setResendEmail] = useState(true);
  const [NewVerify, setNewVerify] = useState(null);

  const [dateOfBirth, setDateOfBirth] = useState({
    day: '',
    month: '',
    year: '',
  });

  const matchYear = new Date();

  function DateOfBirthPicker() {
    const months = [
      { value: 1, name: 'January' },
      { value: 2, name: 'February' },
      { value: 3, name: 'March' },
      { value: 4, name: 'April' },
      { value: 5, name: 'May' },
      { value: 6, name: 'June' },
      { value: 7, name: 'July' },
      { value: 8, name: 'August' },
      { value: 9, name: 'September' },
      { value: 10, name: 'October' },
      { value: 11, name: 'November' },
      { value: 12, name: 'December' },
    ];
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 150 }, (_, i) => currentYear - i);

    const getDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };

    // Array of days based on the selected month and year
    const [days, setDays] = useState([]);

    // Update days when month or year changes
    useEffect(() => {
      if (dateOfBirth.month && dateOfBirth.year) {
        const daysInMonth = getDaysInMonth(dateOfBirth.month, dateOfBirth.year);
        setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
      }
    }, [dateOfBirth.month, dateOfBirth.year]);

    const handleChange = (e) => {
      setDateOfBirth({ ...dateOfBirth, [e.target.name]: e.target.value });
    };

    return (
      <div>
        <select name='day' value={dateOfBirth.day} onChange={handleChange}>
          <option value=''>Day</option>
          {days.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
        <select name='month' value={dateOfBirth.month} onChange={handleChange}>
          <option value=''>Month</option>
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.name}
            </option>
          ))}
        </select>
        <select name='year' value={dateOfBirth.year} onChange={handleChange}>
          <option value=''>Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  }

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  useEffect(() => {
    setNewVerify(Math.floor(100000 + Math.random() * 900000));
    const daysInMonth = getDaysInMonth(dateOfBirth.month, dateOfBirth.year);
    if (dateOfBirth.day > daysInMonth) {
      setDateOfBirth({ ...dateOfBirth, day: '' });
    }
    if (userId !== null && userId !== undefined && userId.length > 5) {
      navigate('/');
    }
    return () => {};
  }, [navigate, userId, dateOfBirth.month, dateOfBirth.year, dateOfBirth.day]);

  const handleGoBack = () => {
    ShowContainer > 1 ? setShowContainer(ShowContainer - 1) : navigate(-1);
  };

  const handleClose = () => {
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
  };

  const handleSecondButton = () => {
    if (
      Name === null ||
      Name === undefined ||
      Name.length < 3 ||
      Name.length > 30
    ) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'အမည် လိုအပ်ပါသည်။' : 'Name is required!',
        message:
          isMM == 'true'
            ? 'ကျေးဇူးပြု၍ မိတ်ဆွေ၏ အမည်ကို ထည့်သွင်းပြီး ထပ်စမ်းကြည့်ပါ။'
            : 'Please enter your real name and try again, please.',
      });
    } else {
      setShowContainer(4);
    }
  };

  const handleThirdButton = () => {
    const thisYear = matchYear.getFullYear();
    if (dateOfBirth.year < thisYear - 10) {
      setShowContainer(5);
    } else {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'အသက်ကန့်သတ်ချက်။' : 'Age Restriction!!',
        message:
          isMM == 'true'
            ? 'BTalk ကို အသုံးပြမည့်သူသည် အနည်းဆုံး အသက် 10 နှစ်ရှိရမည်။ ကျေးဇူးပြု၍ ထပ်ကြိုးစားပါ။'
            : 'The person who will be using BTalk must be at least 10 years old. Please try again.',
      });
    }
  };

  const handelForthContainer = () => {
    if (Gender === undefined || Gender === null || Gender == '') {
      setModalInfo({
        isOpen: true,
        title:
          isMM == 'true' ? 'လိင်အမျိုးအစား လိုအပ်သည်။' : 'Gender is required!',
        message:
          isMM == 'true'
            ? 'သင့်လိင်အမျိုးအစားကို ရွေးချယ်ပြီး ထပ်မံကြိုးစားပါ။'
            : 'You must be choose your gender and try again.',
      });
      return;
    }
    setShowContainer(6);
  };

  const handelFifthContainer = async () => {
    if (ValidateEmail(Email) === false) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'အီးမေးလ် မမှန်ပါ။' : 'Email is invalid!',
        message:
          isMM == 'true'
            ? `မိတ်ဆွေ၏ အီးမေးလ် ${Email} သည် မမှန်ကန်သော အီးမေးလ်ဖြစ်သည်။ ကျေးဇူးပြု၍ ပြန်လည်စစ်ဆေးပြီး မှန်ကန်သော အီးမေးလ်လိပ်စာကို ထည့်ပါ။`
            : `Your email ${Email} is an invalid email. Please enter a valid email address.`,
      });
      return;
    } else {
      setLoading(true);
      await fetch(`${baseURL}users/isUserExists/${Email}`)
        .then((response) => {
          if (response.status === 200) {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true'
                  ? 'အသုံးပြုသူရှိပြီးသား!'
                  : 'User Already Exists!',
              message:
                isMM == 'true'
                  ? 'အဲဒီအီးမေးလ်နဲ့ အကောင့်တစ်ခု ရှိပြီးသားပါ။ တခြားအီးမေးလ်နဲ့ စမ်းကြည့်ရအောင် ဒါမှမဟုတ် မိတ်ဆွေရဲ့ အကောင့်ကို အကောင့်ဝင်ပါ'
                  : "There is an account with that email already exists. Let's try with a different email or login to your account",
            });
            setLoading(false);
            return;
          } else if (response.status === 201) {
            setShowContainer(7);
            setLoading(false);
          }
        })
        .catch((error) => {
          setModalInfo({
            isOpen: true,
            title: isMM == 'true' ? 'တောင်းပန်ပါတယ်' : 'Sorry!',
            message:
              isMM == 'true'
                ? 'တစ်ခုခုမှားသွားသည်၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
                : 'Something went wrong, please try again.',
          });
        });
    }
  };

  let user = {
    name: Name,
    email: Email.toLowerCase(),
    password: Password,
    gender: Gender,
    isAdmin: false,
    birthday: `${dateOfBirth.year}-${dateOfBirth.month}-${
      Number(dateOfBirth.day) + 1
    }`,
    newVerify: NewVerify,
  };

  const EmailData = {
    email: Email.toLowerCase(),
    name: isMM == 'true' ? ` မင်္ဂလာပါ ${Name}` : `Hello ${Name}`,
    subject: isMM == 'true ' ? 'အတည်ပြုကုဒ်' : 'Verification code',
    message:
      isMM == 'true'
        ? `မိတ်ဆွေ၏ အတည်ပြုကုဒ်မှာ ${NewVerify} ဖြစ်ပါသည်။`
        : `Your verification code is ${NewVerify}.`,
    footermsg: isMM == 'true' ? 'လေးစားလျက်' : 'Respectfully',
  };

  const handelSixthButton = () => {
    setLoading(true);
    if (Password === undefined || Password === null || Password.length < 6) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'စကားဝှက်လိုအပ်ပါသည်' : 'Password Required!',
        message:
          isMM == 'true'
            ? 'မိတ်ဆွေ၏ စကားဝှက်သည် အနည်းဆုံး အက္ခရာ ၆ လုံးရှိရမည်။'
            : `Your password must be at least 6 characters.`,
      });
      setLoading(false);
      return;
    } else {
      fetch(`${baseURL}emails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(EmailData),
      })
        .then((res) => {
          if (res.status === 200) {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true'
                  ? 'အီးမေးလ် ပေးပို့ခြင်း အောင်မြင်ပါသည်'
                  : 'Email sent successfully!',
              message:
                isMM == 'true'
                  ? 'မိတ်ဆွေ၏ အီးမေးလ်အကောင့်ကို OTP ပေးပို့ပြီးပါပြီ'
                  : 'OTP has been sent to your email account',
              autoClose: true,
              duration: 3000,
            });
          } else if (res.status === 500) {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true'
                  ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
                  : 'Email Sending Failed!',
              message:
                isMM == 'true'
                  ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
                  : 'Something went wrong while OTP sending your email address.',
              autoClose: true,
              duration: 3000,
            });
          }
        })
        .catch((error) =>
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
                : 'Email Sending Failed!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
                : 'Something went wrong while OTP sending your email address.',
            autoClose: true,
            duration: 3000,
          })
        );
      setShowContainer(8);
      setLoading(false);
    }
    setLoading(false);
  };

  const handelVerify = () => {
    if (EnteredOTP == user.newVerify) {
      try {
        setLoading(true);
        setModalInfo({
          isOpen: true,
          title: isMM == 'true' ? 'ဆောင်ရွက်နေပါသည်...' : 'Loading...',
          message:
            isMM == 'true'
              ? 'အကောင့်ဖန်တီးနေသည်၊ ခဏစောင့်ပါ...'
              : 'Creating account, please wait...',
          autoClose: !Loading,
        });

        fetch(`${baseURL}users/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(user),
        })
          .then(async (response) => {
            if (response.status === 200) {
              setModalInfo({
                isOpen: true,
                title:
                  isMM == 'true'
                    ? 'အကောင့်ဖွင့်ခြင်း အောင်မြင်ပါပြီ!'
                    : 'Registration Success!',
                message:
                  isMM == 'true'
                    ? 'မိတ်ဆွေ၏ အကောင့်ကို စာရင်းသွင်းပြီးပါပြီ။'
                    : 'Your account has been registered.',
                autoClose: true,
                duration: 3000,
              });

              const data = await response.json();
              const userData = data.user;
              const token = data.token;
              const userid = userData.id;

              localStorage.setItem('userId', userid);
              localStorage.setItem('userToken', token);
              navigate('/');
            } else if (response.status === 208) {
              setModalInfo({
                isOpen: true,
                title:
                  isMM == 'true'
                    ? 'အကောင့်ဖွင့်ခြင်း မအောင်မြင်ပါ။'
                    : 'Registration Failed!',
                message:
                  isMM == 'true'
                    ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို အခြားအကောင့်တစ်ခုတွင် စာရင်းသွင်းထားသည်။'
                    : 'Your email address has been registered in another account.',
                autoClose: true,
                duration: 5000,
              });

              setShowContainer(9);
            } else if (response.status === 404) {
              setModalInfo({
                isOpen: true,
                title:
                  isMM == 'true'
                    ? 'အကောင့်ဖွင့်ခြင်း မအောင်မြင်ပါ။'
                    : 'Registration Failed!',
                message:
                  isMM == 'true'
                    ? 'ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
                    : 'Please try again.',
                autoClose: true,
                duration: 3000,
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true'
                  ? 'အကောင့်ဖွင့်ခြင်း မအောင်မြင်ပါ။'
                  : 'Registration Failed!',
              message:
                isMM == 'true'
                  ? 'ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
                  : 'Please try again.',
              autoClose: true,
              duration: 3000,
            });
          });
        setLoading(false);
      } catch (error) {
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'အကောင့်ဖွင့်ခြင်း မအောင်မြင်ပါ။'
              : 'Registration Failed!',
          message:
            isMM == 'true'
              ? 'ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
              : 'Please try again.',
          autoClose: true,
          duration: 3000,
        });
      }
    } else {
      setModalInfo({
        isOpen: true,
        title:
          isMM == 'true' ? 'မှားယွင်းသော အတည်ပြုခြင်း' : 'Wrong Verification',
        message:
          isMM == 'true'
            ? 'ထည့်သွင်းထားသော OTP မှားယွင်းနေပါသည်။ ကျေးဇူးပြ၍ ထပ်စမ်းကြည့်ပါ။'
            : 'You entered OTP is wrong. Please try again',
        autoClose: true,
        duration: 3000,
      });
      setEnteredOTP('');
    }
  };

  const handelChangeEmail = async () => {
    if (ValidateEmail(Email) === false) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'အီးမေးလ် မမှန်ပါ။' : 'Email is invalid!',
        message:
          isMM == 'true'
            ? `မိတ်ဆွေ၏ အီးမေးလ် ${Email} သည် မမှန်ကန်သော အီးမေးလ်ဖြစ်သည်။ ကျေးဇူးပြု၍ ပြန်လည်စစ်ဆေးပြီး မှန်ကန်သော အီးမေးလ်လိပ်စာကို ထည့်ပါ။`
            : `Your email ${Email} is an invalid email. Please enter a valid email address.`,
      });
      return;
    } else {
      setLoading(true);
      await fetch(`${baseURL}users/isUserExists/${Email}`)
        .then((response) => {
          if (response.status === 200) {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true'
                  ? 'အသုံးပြုသူရှိပြီးသား!'
                  : 'User Already Exists!',
              message:
                isMM == 'true'
                  ? 'အဲဒီအီးမေးလ်နဲ့ အကောင့်တစ်ခု ရှိပြီးသားပါ။ တခြားအီးမေးလ်နဲ့ စမ်းကြည့်ရအောင် ဒါမှမဟုတ် မိတ်ဆွေရဲ့ အကောင့်ကို အကောင့်ဝင်ပါ'
                  : "There is an account with that email already exists. Let's try with a different email or login to your account",
            });
            setLoading(false);
            return;
          } else if (response.status === 201) {
            handleResendEmail();
            setResendEmail(true);
            setShowContainer(8);
            setLoading(false);
          }
        })
        .catch((error) => {
          setModalInfo({
            isOpen: true,
            title: isMM == 'true' ? 'တောင်းပန်ပါတယ်' : 'Sorry!',
            message:
              isMM == 'true'
                ? 'တစ်ခုခုမှားသွားသည်၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။'
                : 'Something went wrong, please try again.',
          });
        });
    }
  };

  const handleResendEmail = () => {
    setResendEmail(false);
    fetch(`${baseURL}emails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(EmailData),
    })
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း အောင်မြင်ပါသည်'
                : 'Email sent successfully!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်အကောင့်ကို OTP ပေးပို့ပြီးပါပြီ'
                : 'OTP has been sent to your email account',
            autoClose: true,
            duration: 3000,
          });
        } else if (res.status === 500) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
                : 'Email Sending Failed!',
            message:
              isMM == 'true'
                ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
                : 'Something went wrong while OTP sending your email address.',
            autoClose: true,
            duration: 3000,
          });
        }
      })
      .catch((error) =>
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'အီးမေးလ် ပေးပို့ခြင်း မအောင်မြင်ပါ။'
              : 'Email Sending Failed!',
          message:
            isMM == 'true'
              ? 'မိတ်ဆွေ၏ အီးမေးလ်လိပ်စာကို OTP ပေးပို့ရာတွင် တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။'
              : 'Something went wrong while OTP sending your email address.',
          autoClose: true,
          duration: 3000,
        })
      );
    setTimeout(() => {
      setResendEmail(true);
    }, 180000);
  };

  return (
    <div
      className='registerContainer'
      style={{
        backgroundColor: isDarkMode == 'true' ? '#000' : '#fff',
        minHeight: '100vh',
      }}
    >
      <CustomAlertModal
        isOpen={modalInfo.isOpen}
        onClose={handleClose}
        title={modalInfo.title}
        message={modalInfo.message}
        autoClose={modalInfo.autoClose}
        duration={modalInfo.duration}
      />
      <button onClick={handleGoBack} className='goBackContainer'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 320 512'
          width={20}
        >
          <path
            fill={isDarkMode == 'true' ? '#f0f0f0' : '#222'}
            d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
          />
        </svg>
      </button>
      {ShowContainer === 1 ? (
        <div className='regSecondContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'BTalk ၏ စည်းမျဉ်းများနှင့် မူဝါဒများကို သဘောတူပါသည်။'
              : `Agree to BTalk's terms and policies`}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'ကျွန်ုပ်သဘောတူသည်ကို နှိပ်ခြင်းဖြင့် အကောင့်တစ်ခုဖန်တီးရန်နှင့် '
              : `By tapping I agree, you agree to create an account and `}
          </p>
          <button
            className='policybtn'
            onClick={() => navigate('/PrivacyPolicy')}
          >
            {isMM == 'true'
              ? `BTalk ၏ စည်းမျဉ်းများနှင့် မူဝါဒများကို`
              : `BTalk's Terms and Policies.`}
          </button>
          {isMM == 'true' ? (
            <p
              className='txt'
              style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
            >
              မိတ်ဆွေ သဘောတူပါသည်။
            </p>
          ) : null}
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'စည်းကမ်းသတ်မှတ်ချက်များနှင့် မူဝါဒများသည် သင်အကောင့်တစ်ခုဖန်တီးသည့်အခါ ကျွန်ုပ်တို့စုဆောင်းထားသော အချက်အလက်များကို အသုံးပြုနိုင်သည့်နည်းလမ်းကို ဖော်ပြသည်။ ဥပမာအားဖြင့်၊ ကျွန်ုပ်တို့သည် ကြော်ငြာများအပါအဝင် ကျွန်ုပ်တို့၏ထုတ်ကုန်ကို ထောက်ခံရန်၊ စိတ်ကြိုက်ပြင်ဆင်ရန်နှင့် မြှင့်တင်ရန်အတွက် ဤအချက်အလက်ကို အသုံးပြုပါသည်။'
              : `The Term and Policies describes the way we can use the infromation we collect when you create an account. For example, we use this information to provode, personalize and improve our product, including ads.`}
          </p>

          <button onClick={() => setShowContainer(2)} className='regFirstBtn'>
            {isMM == 'true' ? 'ကျွန်ုပ်သဘောတူသည်' : 'I agree'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 2 ? (
        <div className='regFirstContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true' ? 'BTalk သို့ဝင်ရောက်ပါ။' : 'Join BTalk'}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? `ချိတ်ဆက်ရန်၊ ဆက်သွယ်ရန်၊ ပူးပေါင်းဆောင်ရွက်ရန်၊ အကွာအဝေးများကို ပေါင်းကူးရန်၊ ကွန်မြူနတီများတည်ဆောက်ရန်၊ သင့်ပုံပြင်များမျှဝေရန်၊ သင့်ကမ္ဘာကိုပုံဖော်ရန်အတွက် အကောင့်တစ်ခုဖန်တီးပါ။`
              : `Create an account to Connect, Communicate, Collaborate, Bridging Distances, Building Communities, Share Your Stories, Shape Your World`}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'သင့်အသံ၊ သင့်အသိုင်းအဝိုင်း။ BTalk မှကြိုဆိုပါတယ်။'
              : 'Your Voice, Your Community. Welcome to BTalk.'}
          </p>
          <button onClick={() => setShowContainer(3)} className='regFirstBtn'>
            {isMM == 'true' ? 'စတင်လိုက်ပါ' : 'Get started'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 3 ? (
        <div className='regSecondContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true' ? 'အမည်ဘယ်လိုခေါ်လဲ' : `What's your name?`}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'လက်တွေ့ဘဝတွင် အသုံးပြုသော မိတ်ဆွေ၏ အမည်ကို ထည့်သွင်းပါ။'
              : 'Enter the name you use in real life'}
          </p>
          <input
            className='input'
            type='text'
            value={Name}
            onChange={(text) => setName(text.target.value)}
            placeholder={isMM == 'true' ? 'အမည်ထည့်သွင်းပါ' : 'Enter your name'}
          />
          <button onClick={handleSecondButton} className='regFirstBtn'>
            {isMM == 'true' ? 'ရှေ့သို့' : 'Next'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 4 ? (
        <div className='regThirdContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? `မင်္ဂလာပါ ${Name}။ မွေးနေ့က ဘယ်နေ့လဲ။`
              : `Hi ${Name}! What's your birthday?`}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'မိတ်ဆွေ၏ မွေးသက္ကရာဇ်ကိုရွေးချယ်ပါ။'
              : 'Choose your date of birth.'}
          </p>
          <DateOfBirthPicker />
          <button onClick={handleThirdButton} className='regFirstBtn'>
            {isMM == 'true' ? 'ရှေ့သို့' : 'Next'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 5 ? (
        <div className='regForthContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? `မင်္ဂလာပါ ${Name}။ မိတ်ဆွေရဲ့ လိင်ကဘာလဲ။`
              : `Hi ${Name}! What's your gender?`}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'မိတ်ဆွေ၏ လိင်ကိုရွေးချယ်ပါ။'
              : 'Choose your gender.'}
          </p>
          <div>
            <label className='label'>
              <input
                type='radio'
                className='radio'
                value='Male'
                checked={Gender === 'Male'}
                onChange={(e) => setGender(e.target.value)}
              />
              Male
            </label>
          </div>
          <div>
            <label className='label'>
              <input
                type='radio'
                className='radio'
                value='Female'
                checked={Gender === 'Female'}
                onChange={(e) => setGender(e.target.value)}
              />
              Female
            </label>
          </div>
          <div>
            <label className='label'>
              <input
                type='radio'
                className='radio'
                value='Other'
                checked={Gender === 'Other'}
                onChange={(e) => setGender(e.target.value)}
              />
              Other
            </label>
          </div>

          <button onClick={handelForthContainer} className='regFirstBtn'>
            {isMM == 'true' ? 'ရှေ့သို့' : 'Next'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 6 ? (
        <div className='regFifthContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true' ? 'မိတ်ဆွေ၏ အီးမေးလ်က ဘာလဲ။' : `What's your email?`}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'ဆက်သွယ်ရမည့် အီးမေးလ်ကို ထည့်ပါ။ သင့်ပရိုဖိုင်တွင် ဤအရာကို မည်သူမျှ မြင်မည်မဟုတ်ပါ။'
              : 'Enter the email where you can be contacted. No one will see this on your profile.'}
          </p>
          <input
            className='input'
            type='email'
            value={Email}
            onChange={(text) => setEmail(text.target.value)}
            placeholder={
              isMM == 'true' ? 'အီးမေးလ် ထည့်သွင်းပါ' : 'Enter your email'
            }
          />
          <button
            onClick={Loading ? null : handelFifthContainer}
            className='regFirstBtn'
          >
            {Loading
              ? isMM == 'true'
                ? 'လုပ်ဆောင်နေပါသည်။'
                : 'Loading...'
              : isMM == 'true'
              ? 'ရှေ့သို့'
              : 'Next'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 7 ? (
        <div className='regSecondContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true' ? 'စကားဝှက်တစ်ခုဖန်တီးပါ' : 'Create a password'}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'အနည်းဆုံး စာလုံး သို့မဟုတ် နံပါတ် ၆ခု ဖြင့် စကားဝှက်တစ်ခု ဖန်တီးပါ။ တခြားမခန့်မှန်းနိုင်တဲ့ အရာဖြစ်သင့်တယ်။'
              : `Create a password with at least 6 letters or numbers. It should be something other can't guess.`}
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className='input password'
              type={ShowPassword ? 'text' : 'password'}
              value={Password}
              onChange={(text) => setPassword(text.target.value)}
              placeholder={
                isMM == 'true'
                  ? 'စကားဝှက်အသစ်တစ်ခုဖန်တီးပါ။'
                  : 'Create a new password'
              }
            />
            <button
              className='passshow'
              onClick={() => setShowPassword(!ShowPassword)}
            >
              {ShowPassword ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 640 512'
                  width={25}
                >
                  <path d='M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z' />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  width={25}
                >
                  <path d='M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z' />
                </svg>
              )}
            </button>
          </div>
          <button
            onClick={Loading ? null : handelSixthButton}
            className='regFirstBtn'
          >
            {Loading
              ? isMM == 'true'
                ? 'လုပ်ဆောင်နေပါသည်။'
                : 'Loading...'
              : isMM == 'true'
              ? 'ရှေ့သို့'
              : 'Next'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 8 ? (
        <div className='regSecondContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'မိတ်ဆွေ၏ အကောင့်ကိုအတည်ပြုပါ'
              : 'Verify your account'}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? `အကောင့်ကို အတည်ပြုရန် မိတ်ဆွေ၏ အီးမေးလ် ${Email} သို့ ကျွန်ုပ်တို့ ပေးပို့ထားသော OTP ကို ထည့်သွင်းပါ။`
              : `Enter OTP that we sent to your email ${Email} to verify your account.`}
          </p>
          <input
            className='input'
            type='number'
            value={EnteredOTP}
            onChange={(text) => setEnteredOTP(text.target.value)}
            placeholder={
              isMM == 'true' ? 'OTP နံပါတ်ထည့်ပါ။' : 'Enter OTP Number'
            }
          />
          <button
            onClick={Loading ? null : handelVerify}
            className='regFirstBtn'
          >
            {Loading
              ? isMM == 'true'
                ? 'လုပ်ဆောင်နေပါသည်။'
                : 'Loading...'
              : isMM == 'true'
              ? 'အတည်ပြုပါ'
              : 'Verify'}
          </button>
          {ResendEmail ? (
            <button className='regFirstBtn' onClick={handleResendEmail}>
              {isMM == true ? 'အီးမေးလ် ပြန်လည်ပေးပို့ပါ' : 'Resend Email'}
            </button>
          ) : null}
          {!ResendEmail ? (
            <p
              style={{
                color: isDarkMode == 'true' ? '#f0f0f0' : '#222',
                marginTop: 20,
              }}
            >
              {isMM == 'true'
                ? 'နောက်တစ်ကြိမ်ထပ်မံပေးပို့လိုပါက သုံးမိနစ်စောင့်ပါ'
                : 'Wait three minutes if you want to send it again'}
            </p>
          ) : null}
          <button className='regFirstBtn' onClick={() => setShowContainer(9)}>
            {isMM == 'true' ? 'အီးမေးလ်မှားနေပါသည်' : 'Incorrect email'}
          </button>
        </div>
      ) : null}
      {ShowContainer === 9 ? (
        <div className='regFifthContainer'>
          <p
            className='joinBTalkTxt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'အခြားအီးမေးလ်ကို သုံးလိုပါသလား။'
              : 'Do you want to use another email?'}
          </p>
          <p
            className='txt'
            style={{ color: isDarkMode == 'true' ? '#f0f0f0' : '#222' }}
          >
            {isMM == 'true'
              ? 'ဆက်သွယ်ရမည့် အီးမေးလ်ကို ထည့်ပါ။ သင့်ပရိုဖိုင်တွင် ဤအရာကို မည်သူမျှ မြင်မည်မဟုတ်ပါ။'
              : 'Enter the email where you can be contacted. No one will see this on your profile.'}
          </p>
          <input
            className='input'
            type='email'
            value={Email}
            onChange={(text) => setEmail(text.target.value)}
            placeholder={
              isMM == 'true' ? 'အီးမေးလ် ထည့်သွင်းပါ' : 'Enter your email'
            }
          />
          <button
            onClick={Loading ? null : handelChangeEmail}
            className='regFirstBtn'
          >
            {Loading
              ? isMM == 'true'
                ? 'လုပ်ဆောင်နေပါသည်။'
                : 'Loading...'
              : isMM == 'true'
              ? 'အီးမေးလ်ပြောင်းပါ'
              : 'Change Email'}
          </button>
        </div>
      ) : null}

      <button className='gotoLogin' onClick={() => navigate('/login')}>
        {isMM == 'true' ? 'အကောင့်ရှိပြီးသားလား' : 'Already have an account?'}
      </button>
    </div>
  );
};

export default Register;
