import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from './../../Images/logotran.png';
import BlueVerifyBadge from '../../Images/BTalk_Verified_Badge.png';
import Navbar from '../../Utils/NavBar';
import useLocalStorage from '../../Utils/useLocalStorage';

import './Menu.css';
import CustomActionAlertModal from '../../Utils/customActionAlert';

import baseURL from '../../Assets/common/baseUrl';
import imageBaseURL from '../../Assets/common/imageBaseURL';

const Menu = () => {
  const navigate = useNavigate();

  const [userId, setuserId, clearuserId] = useLocalStorage('userId');
  const [isMM, setisMM, clearisMM] = useLocalStorage('isMM');
  const [userToken, setuserToken, clearuserToken] =
    useLocalStorage('userToken');
  const [isDarkMode, setisDarkMode, clearisDarkMode] =
    useLocalStorage('isDarkMode');
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  const [UserProfile, setUserProfile] = useState();
  const [Loading, setLoading] = useState(true);

  const toggleTheme = () => {
    setisDarkMode(isDarkMode === 'true' ? 'false' : 'true');
  };
  const toggleLanguage = () => {
    setisMM(isMM === 'true' ? 'false' : 'true');
  };

  const handleLogout = () => {
    setModalInfo({
      isOpen: true,
      title: isMM === 'true' ? 'အတည်ပြုပေးပါ' : 'Please Confirm',
      message:
        isMM === 'true'
          ? 'အကောင့်ထွက်မှာ သေချာပါသလား'
          : 'Are you sure to logout?',
      autoClose: false,
    });
  };

  const handleClose = () => {
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
  };

  const handleConfirm = async () => {
    let key = ['userId', 'userToken'];
    await clearuserId();
    await clearuserToken();
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
    navigate('/login');
  };

  useEffect(() => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return;
    } else {
      getUserAPI();
    }
    return () => {
      // setLoading(true);
    };
  }, [navigate]);

  const getUserAPI = async () => {
    await fetch(`${baseURL}users/${userId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    }).then(async (res) => {
      const data = await res.json();
      setUserProfile(data);
    });
  };

  return (
    <div
      style={{
        backgroundColor: isDarkMode === 'true' ? '#222' : '#f0f0f0',
        minHeight: '100vh',
      }}
    >
      <CustomActionAlertModal
        isOpen={modalInfo.isOpen}
        onConfirm={handleConfirm}
        onClose={handleClose}
        title={modalInfo.title}
        message={modalInfo.message}
        autoClose={modalInfo.autoClose}
        duration={modalInfo.duration}
      />
      <Navbar screen='Menu' />
      <div className='menuContainer'>
        <div className='header'>
          <div className='btalk'>
            <p className='btalkTxt'>BTalk</p>
          </div>
          <div className='searchContainer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={30}
              viewBox='0 0 512 512'
            >
              <path
                fill='#f4b83c'
                d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z'
              />
            </svg>
          </div>
        </div>

        <div className='menuItemsContainer'>
          <div
            className='menuItem'
            style={{ backgroundColor: isDarkMode === 'true' ? '#000' : '#fff' }}
          >
            <button className='menuItemBtn'>
              <div className='menuItemIconContainer'>
                {UserProfile ? (
                  UserProfile.profilePicture == '' ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={30}
                      viewBox='0 0 448 512'
                    >
                      <path
                        fill='#f4b83c'
                        d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
                      />
                    </svg>
                  ) : (
                    <img
                      className='userImg'
                      src={`${imageBaseURL}${UserProfile.profilePicture}`}
                      alt=''
                    />
                  )
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={30}
                    viewBox='0 0 448 512'
                  >
                    <path
                      fill='#f4b83c'
                      d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
                    />
                  </svg>
                )}
              </div>
              <div className='menuItemTxtContainer'>
                <p className='menuItemTxt'>
                  {UserProfile ? UserProfile.name : 'Guest'}
                </p>
              </div>
              {UserProfile ? (
                UserProfile.verifiedUser == true ? (
                  <img className='blueBidgeimg' src={BlueVerifyBadge} alt='' />
                ) : null
              ) : null}
            </button>
          </div>
          <div
            className='menuItem'
            style={{ backgroundColor: isDarkMode === 'true' ? '#000' : '#fff' }}
          >
            <button className='menuItemBtn' onClick={toggleLanguage}>
              <div className='menuItemIconContainer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 640 512'
                  width={30}
                >
                  <path
                    fill='#f4b83c'
                    d='M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z'
                  />
                </svg>
              </div>
              <div className='menuItemTxtContainer'>
                <p className='menuItemTxt'>
                  {isMM === 'true' ? 'English Language' : 'မြန်မာဘာသာ'}
                </p>
              </div>
            </button>
          </div>
          <div
            className='menuItem'
            style={{ backgroundColor: isDarkMode === 'true' ? '#000' : '#fff' }}
          >
            <button className='menuItemBtn' onClick={toggleTheme}>
              <div className='menuItemIconContainer'>
                {isDarkMode === 'true' ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    width={30}
                  >
                    <path
                      fill='#f4b83c'
                      d='M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 384 512'
                    width={30}
                  >
                    <path
                      fill='#f4b83c'
                      d='M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z'
                    />
                  </svg>
                )}
              </div>
              <div className='menuItemTxtContainer'>
                <p className='menuItemTxt'>
                  {isMM === 'true'
                    ? isDarkMode === 'true'
                      ? 'အလင်း'
                      : 'အမှောင်'
                    : isDarkMode === 'true'
                    ? 'Light Mode'
                    : 'Dark Mode'}
                </p>
              </div>
            </button>
          </div>
          <div
            className='menuItem'
            style={{ backgroundColor: isDarkMode === 'true' ? '#000' : '#fff' }}
          >
            <button className='menuItemBtn'>
              <div className='menuItemIconContainer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 640 512'
                  width={30}
                >
                  <path
                    fill='#f4b83c'
                    d='M544 248v3.3l69.7-69.7c21.9-21.9 21.9-57.3 0-79.2L535.6 24.4c-21.9-21.9-57.3-21.9-79.2 0L416.3 64.5c-2.7-.3-5.5-.5-8.3-.5H296c-37.1 0-67.6 28-71.6 64H224V248c0 22.1 17.9 40 40 40s40-17.9 40-40V176c0 0 0-.1 0-.1V160l16 0 136 0c0 0 0 0 .1 0H464c44.2 0 80 35.8 80 80v8zM336 192v56c0 39.8-32.2 72-72 72s-72-32.2-72-72V129.4c-35.9 6.2-65.8 32.3-76 68.2L99.5 255.2 26.3 328.4c-21.9 21.9-21.9 57.3 0 79.2l78.1 78.1c21.9 21.9 57.3 21.9 79.2 0l37.7-37.7c.9 0 1.8 .1 2.7 .1H384c26.5 0 48-21.5 48-48c0-5.6-1-11-2.7-16H432c26.5 0 48-21.5 48-48c0-12.8-5-24.4-13.2-33c25.7-5 45.1-27.6 45.2-54.8v-.4c-.1-30.8-25.1-55.8-56-55.8c0 0 0 0 0 0l-120 0z'
                  />
                </svg>
              </div>
              <div className='menuItemTxtContainer'>
                <p className='menuItemTxt'>
                  {isMM === 'true'
                    ? 'အကူအညီနှင့် မူဝါဒများဌာန'
                    : 'Help & Policies Center'}
                </p>
              </div>
            </button>
          </div>
          <div
            className='menuItem'
            style={{ backgroundColor: isDarkMode === 'true' ? '#000' : '#fff' }}
          >
            <button className='menuItemBtn'>
              <div className='menuItemIconContainer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  width={30}
                >
                  <path
                    fill='#f4b83c'
                    d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z'
                  />
                </svg>
              </div>
              <div className='menuItemTxtContainer'>
                <p className='menuItemTxt'>
                  {isMM === 'true' ? 'ဆက်တင်များ' : 'Settings'}
                </p>
              </div>
            </button>
          </div>
          <div
            className='menuItem'
            style={{ backgroundColor: isDarkMode === 'true' ? '#000' : '#fff' }}
          >
            <button className='menuItemBtn' onClick={handleLogout}>
              <div className='menuItemIconContainer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  width={30}
                >
                  <path
                    fill='#f4b83c'
                    d='M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z'
                  />
                </svg>
              </div>
              <div className='menuItemTxtContainer'>
                <p className='menuItemTxt'>
                  {isMM === 'true' ? 'အကောင့်ထွက်မည်' : 'Logout'}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
