import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from './../../Images/logotran.png';
import useLocalStorage from '../../Utils/useLocalStorage';
import baseURL from '../../Assets/common/baseUrl';

import CustomAlertModal from '../../Utils/CustomAlertModal';

import './Auth.css';

const Login = () => {
  const navigate = useNavigate();

  // const userId = localStorage.getItem('userId');

  const [userId, setuserId, clearuserId] = useLocalStorage('userId', null);
  const [isMM, setisMM, clearisMM] = useLocalStorage('isMM', false);
  const [userToken, setuserToken, clearuserToken] = useLocalStorage(
    'userToken',
    null
  );
  const [isDarkMode, setisDarkMode, clearisDarkMode] = useLocalStorage(
    'isDarkMode',
    false
  );

  const [showPassword, setshowPassword] = useState(false);
  const [Password, setPassword] = useState('');
  const [Email, setEmail] = useState('');
  const [Loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  const toggleTheme = () => {
    setisDarkMode(isDarkMode == 'true' ? 'false' : 'true');
  };
  const toggleLanguage = () => {
    setisMM(isMM == 'true' ? 'false' : 'true');
  };

  useEffect(() => {
    if (userId === null) {
    } else if (
      (userId !== undefined && userId === 'Guest') ||
      userId.length > 4
    ) {
      navigate('/');
    }
    return () => {
      setLoading(false);
      setEmail('');
      setPassword('');
    };
  }, [navigate, userId]);

  function validateEmail(email) {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  const handleLogin = async () => {
    setLoading(true);
    if (validateEmail(Email) === false) {
      setModalInfo({
        isOpen: true,
        title: isMM ? 'အီးမေးလ်မှားနေပါသည်' : 'Invalid Email',
        message: isMM
          ? `မိတ်ဆွေ၏ အီးမေးလ် ${Email} သည် မှားနေပါသည်။ ကျေးဇူးပြု၍ မှန်ကန်သော အီးမေးလ်လိပ်စာတစ်ခု ဖြည့်ပါ။`
          : `Your email ${Email} is an invalid email. Please enter a valid email address.`,
      });
      setLoading(false);
    } else if (
      Password === null ||
      Password === undefined ||
      Password.length < 6
    ) {
      setModalInfo({
        isOpen: true,
        title: isMM ? 'မှားယွင်းသော စကားဝှက်' : 'Invalid password!',
        message: isMM
          ? 'မိတ်ဆွေ၏ စကားဝှက် မှားနေပါသည်။ စကားဝှက်သည် အနည်းဆုံး အက္ခရာ ၆ လုံးရှိပါမည်။'
          : 'Invalid password. Your password must be at least 6 characters.',
      });
      setLoading(false);
    } else {
      setModalInfo({
        isOpen: true,
        title: isMM ? 'အကောင့် ဝင်နေပါသည်...' : 'Logging in...!',
        message: isMM
          ? 'အကောင့်ဝင်နေပါသည်။ ကျေးဇူးပြုပြီးခဏစောင့်ပါ...'
          : 'Signing in Please wait...',
        autoClose: true,
        duration: 3000,
      });

      const user = { email: Email, password: Password };
      fetch(`${baseURL}users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      })
        .then(async (response) => {
          if (response.status === 200) {
            const data = await response.json();
            setModalInfo({
              isOpen: true,
              title: isMM == 'true' ? 'အောင်မြင်ပါသည်' : 'Success!',
              message:
                isMM == 'true'
                  ? 'အကောင့်ဝင်ပြီးပါပြီ။'
                  : 'Logged in successfully',
              autoClose: true,
              duration: 2000,
            });

            const user = data.user;
            const token = data.token;
            if (user.newVerify === null || user.newVerify === undefined) {
              localStorage.setItem('userId', user.id);
              localStorage.setItem('userToken', token);
              navigate('/');
            } else {
              navigate('/RegVerify', { state: { newVerifyData: user } });
            }
            setLoading(false);
          } else if (response.status == 400) {
            setModalInfo({
              isOpen: true,
              title: isMM ? 'စကားဝှက် မမှန်ပါ။' : 'Incorrect Password!',
              message: isMM
                ? 'ကျေးဇူးပြု၍ မှန်ကန်သော စကားဝှက်ကို ထည့်သွင်းပြီး ထပ်စမ်းကြည့်ပါ။'
                : 'Please enter your correct password and try again.',
              autoClose: true,
              autoClose: 4000,
            });

            setLoading(false);
          } else if (response.status == 404) {
            setModalInfo({
              isOpen: true,
              title: isMM ? 'အကောင့်မတွေ့ပါ။' : 'Account not found!',
              message: isMM
                ? `${Email} အီးမေးလ်လိပ်စာဖြင့် BTalk အကောင့်ကို ရှာမတွေ့ပါ။ `
                : `There is no BTalk account found with your email address. ${Email} `,
              autoClose: true,
              autoClose: 4000,
            });

            setLoading(false);
          }

          setLoading(false);
        })
        .catch((error) => {
          setModalInfo({
            isOpen: true,
            title: isMM ? 'မမျှော်လင့်ထားသော အမှား' : 'Unexpected Error!',
            message: isMM
              ? `မမျှော်လင့်ထားသော အမှားတစ်ခု ဖြစ်ပွားခဲ့သည်။ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။`
              : `Unexpected Error Occurred. Please try again`,
            autoClose: true,
            autoClose: 4000,
          });

          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
  };

  const handleGuestLogin = () => {
    try {
      localStorage.setItem('userId', 'Guest');
      localStorage.setItem('userToken', 'Guest');
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'အောင်မြင်ပါသည်' : 'Success!',
        message:
          isMM == 'true'
            ? 'ဂုဏ်ပြုပါသည်။ မိတ်ဆွေသည် ဧည့်သည်အဖြစ် အောင်မြင်စွာ အကောင့်ဝင်ပြီးပါပြီ။'
            : 'Congrats! You have successfully logged as guest account.',
        autoClose: true,
        duration: 2000,
      });
      navigate('/');
    } catch (error) {
      setModalInfo({
        isOpen: true,
        title: isMM == 'true' ? 'မအောင်မြင်ပါ' : 'Failed!',
        message:
          isMM == 'true'
            ? 'ဧည့်သည်အဖြစ် အကောင့်ဝင်နေစဉ် တစ်စုံတစ်ခု မှားယွင်းနေပါသည်။ ကျေးဇူးပြု၍ ထပ်ကြိုးစားပါ။'
            : 'Something was wrong during logging in as guest. Try again, please.',
        autoClose: true,
        duration: 2000,
      });
    }
  };

  const handleForgotPassword = () => {
    navigate('/ForgotPassword');
  };

  return (
    <div
      style={{
        backgroundColor: isDarkMode == 'true' ? '#000' : '#fff',
        minHeight: '100vh',
      }}
    >
      <CustomAlertModal
        isOpen={modalInfo.isOpen}
        onClose={handleClose}
        title={modalInfo.title}
        message={modalInfo.message}
        autoClose={modalInfo.autoClose}
        duration={modalInfo.duration}
      />
      <div className='loginFirstContainer'>
        <button
          className='languageBtn'
          onClick={toggleLanguage}
          style={{
            borderWidth: 1,
            borderRadius: 20,
            height: 50,
            width: 80,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <p
            className='languageTxt'
            style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}
          >
            {isMM ? 'English' : 'မြန်မာ'}
          </p>
        </button>
        <div className={isDarkMode == 'true' ? 'dark-theme' : ''}>
          <button onClick={toggleTheme} className='theme-button'></button>
        </div>
      </div>
      <div className='logoContainer'>
        <img src={Logo} className='logo' />
      </div>
      <div className='inputContainer'>
        <div className='inputItemsContainer'>
          <input
            type='email'
            className='inputItem'
            onChange={(e) => setEmail(e.target.value)}
            value={Email}
            placeholder={
              isMM ? 'သင်၏ အီးမေးလ်လိပ်စာကိုထည့်ပါ' : 'Enter your email'
            }
          />
        </div>
        <div className='inputItemsContainer passwordContainer'>
          <input
            type={!showPassword ? 'password' : 'text'}
            onChange={(e) => setPassword(e.target.value)}
            value={Password}
            className='inputItem'
            placeholder={
              isMM ? 'သင်၏ စကားဝှက်ကိုထည့်ပါ' : 'Enter your password'
            }
          />

          <button
            onClick={() => setshowPassword(!showPassword)}
            className='showPassBtn'
          >
            {!showPassword ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={30}
                viewBox='0 0 576 512'
              >
                <path d='M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z' />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={30}
                viewBox='0 0 640 512'
              >
                <path d='M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z' />
              </svg>
            )}
          </button>
        </div>
        <div className='fupContainer'>
          <button className='fupBtn' onClick={handleForgotPassword}>
            <p className='fuptxt'>
              {isMM ? 'စကားဝှက်မေ့နေပါသလား?' : 'Forgotten your password?'}
            </p>
          </button>
        </div>
      </div>

      <div className='btnContainer'>
        <button onClick={() => handleLogin()} className='loginbtn'>
          {Loading
            ? isMM
              ? 'လုပ်ဆောင်နေပါသည်...'
              : 'Loading...'
            : isMM
            ? 'အကောင့်ဝင်ပါ'
            : 'Login'}
        </button>

        <p className='nyrtxt'>
          {isMM ? 'အကောင့်မရှိသေးဘူးလား?' : 'Not yet registered?'}
        </p>
        <button
          className='cnabtn'
          onClick={() => navigate('/Register')}
          style={{
            color: isDarkMode == 'true' ? '#f0f0f0' : '#222',
            borderColor: isDarkMode == 'true' ? '#f0f0f0' : '#222',
          }}
        >
          {isMM ? 'အကောင့်အသစ်ဖွင့်ပါ' : 'Create new account'}
        </button>
        <p className='ortxt'>{isMM ? 'သို့မဟုတ်' : 'Or'}</p>
        <button
          onClick={handleGuestLogin}
          className='cnabtn'
          style={{
            color: isDarkMode == 'true' ? '#f0f0f0' : '#222',
            borderColor: isDarkMode == 'true' ? '#f0f0f0' : '#222',
          }}
        >
          {isMM ? 'ဧည့်သည်အဖြစ် ဝင်ရောက်ပါ' : 'Login as guest'}
        </button>
      </div>
      <div className='footerContainer'>
        <button className='fupBtn'>
          <p className='fuptxt'>
            {isMM
              ? 'BTalk ၏ စည်းမျဉ်းများနှင့် မူဝါဒများ'
              : `BTalk's Terms & Policies`}
          </p>
        </button>
      </div>
    </div>
  );
};

export default Login;
