import React, { useEffect } from 'react';
import './Modal.css'; // Ensure this CSS file is correctly linked for styling

function CustomActionAlertModal({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  autoClose = false,
  duration = 3000,
}) {
  useEffect(() => {
    let timer;
    if (isOpen && autoClose) {
      timer = setTimeout(() => {
        onClose();
      }, duration); // Close the modal after `duration` milliseconds
    }
    return () => clearTimeout(timer); // Clean up the timer when the component unmounts or updates
  }, [isOpen, autoClose, duration, onClose]);
  if (!isOpen) return null;

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-container' onClick={(e) => e.stopPropagation()}>
        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          <h2 className='modal-title'>{title}</h2>
        </div>
        <div className='modal-body'>
          <p className='modal-message'>{message}</p>
        </div>
        <div className='modal-footer action'>
          <button className='modal-button' onClick={onClose}>
            No
          </button>
          <button className='modal-button' onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomActionAlertModal;
