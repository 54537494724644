import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from './../../Images/logotran.png';
import Navbar from '../../Utils/NavBar';

const Chat = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId === null) {
      // navigate('/Login');
    }
  }, [navigate]);

  return (
    <div>
      <Navbar screen='Chat' />
      <div
        style={{
          marginTop: 80,
        }}
      ></div>
    </div>
  );
};

export default Chat;
