import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import baseURL from '../../../Assets/common/baseUrl';
import imageBaseURL from '../../../Assets/common/imageBaseURL';

import './MonthlyMagazine.css';

const MonthlyMagazine = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const magazineId = queryParams.get('magazineid');

  const isDarkMode = localStorage.getItem('isDarkMode');
  const isMM = localStorage.getItem('isMM');
  const userToken = localStorage.getItem('userToken');
  const userId = localStorage.getItem('userId');
  const horizational = true;

  const [Loading, setLoading] = useState(true);
  const [Refreshing, setRefreshing] = useState(false);
  const [MyanmarsarArray, setMyanmarsarArray] = useState([]);
  const [PoemArray, setPoemArray] = useState([]);
  const [ShortStoryArray, setShortStoryArray] = useState([]);
  const [EssayArray, setEssayArray] = useState([]);
  const [ArticleArray, setArticleArray] = useState([]);
  const [StoryArray, setStoryArray] = useState([]);
  const [YoungArray, setYoungArray] = useState([]);
  const [NewAuthorStoryArray, setNewAuthorStoryArray] = useState([]);
  const [ThukumaArray, setThukumaArray] = useState([]);
  const [FunnyArray, setFunnyArray] = useState([]);
  const [CartoonArray, setCartoonArray] = useState([]);
  const [magazine, setmagazine] = useState('');

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navStyle, setNavStyle] = useState({ top: '0px' });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setNavStyle({ top: '-80px' }); // Hide navbar
      } else {
        setNavStyle({ top: '0px' }); // Show navbar
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    fetchMagazine();
    fetchAllMagazine();
    setTimeout(() => {}, 1000);
    return () => {};
  }, [magazineId]);

  const fetchMagazine = async () => {
    const res = await fetch(`${baseURL}magazines/singlemagazine/${magazineId}`);
    if (res.status === 400) {
      navigate('*');
    }
    const data = await res.json();
    setmagazine(data);
    setLoading(false);
  };

  const fetchMagazineApi = async (data) => {
    try {
      const res = await fetch(`${baseURL}magazines/${data}/${magazineId}`);
      const resdata = await res.json();
      if (data == 'poem') {
        setPoemArray(resdata);
      } else if (data == 'shortstory') {
        setShortStoryArray(resdata);
      } else if (data == 'essay') {
        setEssayArray(resdata);
      } else if (data == 'article') {
        setArticleArray(resdata);
      } else if (data == 'story') {
        setStoryArray(resdata);
      } else if (data == 'newauthorstory') {
        setNewAuthorStoryArray(resdata);
      } else if (data == 'funny') {
        setFunnyArray(resdata);
      } else if (data == 'cartoon') {
        setCartoonArray(resdata);
      } else if (data == 'myanmarsar') {
        setMyanmarsarArray(resdata);
      } else if (data == 'young') {
        setYoungArray(resdata);
      } else if (data == 'thukuma') {
        setThukumaArray(resdata);
      }
      setLoading(false);
      setRefreshing(false);
    } catch (error) {}
  };

  const fetchMagazineMyanmarsar = () => {
    fetchMagazineApi('myanmarsar');
  };
  const fetchMagazinePoem = () => {
    fetchMagazineApi('poem');
  };
  const fetchMagazineShortStory = () => {
    fetchMagazineApi('shortstory');
  };
  const fetchMagazineEssay = () => {
    fetchMagazineApi('essay');
  };
  const fetchMagazineArticle = () => {
    fetchMagazineApi('article');
  };
  const fetchMagazineStory = () => {
    fetchMagazineApi('story');
  };
  const fetchMagazineYoung = () => {
    fetchMagazineApi('young');
  };
  const fetchMagazineNewAuthorStory = () => {
    fetchMagazineApi('newauthorstory');
  };
  const fetchMagazineThukuma = () => {
    fetchMagazineApi('thukuma');
  };
  const fetchMagazineFunny = () => {
    fetchMagazineApi('funny');
  };
  const fetchMagazineCartoon = () => {
    fetchMagazineApi('cartoon');
  };

  const fetchAllMagazine = () => {
    fetchMagazineMyanmarsar();
    fetchMagazinePoem();
    fetchMagazineShortStory();
    fetchMagazineEssay();
    fetchMagazineArticle();
    fetchMagazineStory();
    fetchMagazineYoung();
    fetchMagazineNewAuthorStory();
    fetchMagazineThukuma();
    fetchMagazineFunny();
    fetchMagazineCartoon();
    setLoading(false);
  };

  const goToSingleContent = (post, type) => {
    navigate(
      `/singlemagazinecontent?magazineid=${magazineId}&type=${type}&contentid=${post._id}`
    );
  };

  return (
    <div>
      <div className='monthlynav' style={navStyle}>
        <div
          style={{
            height: 70,
            backgroundColor: isDarkMode == 'true' ? '#000' : '#fff',
            alignItems: 'center',
            width: '100%',
            paddingLeft: 20,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => navigate('/book')}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              backgroundColor: 'inherit',
              cursor: 'pointer',
              border: 0,
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 320 512'
              width={25}
            >
              <path
                fill={isDarkMode == 'true' ? '#fff' : '#000'}
                d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
              />
            </svg>
            <p
              style={{
                color: isDarkMode == 'true' ? '#fff' : '#333',
                fontSize: 15,
                marginLeft: 5,
              }}
            >
              {isMM ? 'နောက်သို့' : 'Go Back'}
            </p>
          </button>
          <div>
            <p
              className='magazineTitle'
              style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}
            >
              BTalk Magazine {magazine.magazineDate}
            </p>
          </div>
          <div></div>
        </div>
      </div>
      <div className='childsContainer'>
        {Loading ? (
          <div
            style={{
              alignItems: 'center',
              height: '100vh',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                marginTop: 20,
                color: isDarkMode ? 'gainsboro' : '#333',
              }}
            >
              {isMM
                ? 'လုပ်ဆောင်နေပါသည်။ ကျေးဇူးပြု၍ ခေတ္တစောင့်ဆိုင်းပေးပါ။'
                : 'Please wait! Loading...'}
            </p>
          </div>
        ) : (
          <div
            style={{
              alignItems: 'center',
              paddingTop: 80,
              margin: 'auto',
            }}
          >
            <div>
              <img
                src={`${imageBaseURL}${magazine.coverImage}`}
                className='imgs'
              />
            </div>

            <div>
              <img
                src={`${imageBaseURL}${magazine.editorsImage}`}
                className='imgs'
              />
            </div>
            <div
              style={{
                marginTop: 50,
                margin: 'auto',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p style={{ color: '#FC3C44', fontWeight: 'bold', fontSize: 23 }}>
                မာတိကာ
              </p>
              <div className='mtkContainer'>
                {MyanmarsarArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      မြန်မာစာ အရေးအသား
                    </p>
                    {MyanmarsarArray.map((item, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(item, 'myanmarsar')}
                        key={index}
                      >
                        <p>{item.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{item.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {PoemArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      ကဗျာ
                    </p>
                    {PoemArray.map((poem, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(poem, 'poem')}
                        key={index}
                      >
                        <p>{poem.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{poem.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {ShortStoryArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      စိတ်ခံတွင်းနှူးဝတ္ထုတို
                    </p>
                    {ShortStoryArray.map((shortStory, index) => (
                      <button
                        className='matikarItem'
                        onClick={() =>
                          goToSingleContent(shortStory, 'shortstory')
                        }
                        key={index}
                      >
                        <p>{shortStory.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{shortStory.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {EssayArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      အက်ဆေး
                    </p>
                    {EssayArray.map((essay, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(essay, 'essay')}
                        key={index}
                      >
                        <p>{essay.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{essay.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {ArticleArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      ဆောင်းပါး
                    </p>
                    {ArticleArray.map((article, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(article, 'article')}
                        key={index}
                      >
                        <p>{article.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{article.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {StoryArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      ဝတ္ထုတို
                    </p>
                    {StoryArray.map((story, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(story, 'story')}
                        key={index}
                      >
                        <p>{story.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{story.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {YoungArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      လူငယ်ဖတ်စာ အတိုအထွာ
                    </p>
                    {YoungArray.map((item, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(item, 'young')}
                        key={index}
                      >
                        <p>{item.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{item.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {NewAuthorStoryArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      ပုရစ်ဖူးဝတ္ထုတို
                    </p>
                    {NewAuthorStoryArray.map((story, index) => (
                      <button
                        className='matikarItem'
                        onClick={() =>
                          goToSingleContent(story, 'newauthorstory')
                        }
                        key={index}
                      >
                        <p>{story.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{story.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {ThukumaArray.length > 0 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      သုခုမသဘင်
                    </p>
                    {ThukumaArray.map((item, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(item, 'thukuma')}
                        key={index}
                      >
                        <p>{item.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{item.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {FunnyArray.length > 1 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      ရယ်စရာ
                    </p>
                    {FunnyArray.map((funny, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(funny, 'funny')}
                        key={index}
                      >
                        <p>{funny.authorname}</p>
                        <p style={{ fontWeight: 'bold' }}>{funny.title}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
                {CartoonArray.length > 1 ? (
                  <div className='mtkItemContainer'>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#fc3c44',
                      }}
                    >
                      ကာတွန်း
                    </p>
                    {CartoonArray.map((cartoon, index) => (
                      <button
                        className='matikarItem'
                        onClick={() => goToSingleContent(cartoon, 'cartoon')}
                        key={index}
                      >
                        <p>{cartoon.authorname}</p>
                      </button>
                    ))}
                  </div>
                ) : null}
              </div>
              <div style={{ marginTop: 50 }}>
                {MyanmarsarArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>မြန်မာစာ အရေးအသား</p>
                    <div className='underlineDiv'></div>
                    {MyanmarsarArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {PoemArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>ကဗျာ</p>
                    <div className='underlineDiv'></div>

                    {PoemArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {ShortStoryArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>စိတ်ခံတွင်းနှူးဝတ္ထုတို</p>
                    <div className='underlineDiv'></div>
                    {ShortStoryArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {EssayArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>အက်ဆေး</p>
                    <div className='underlineDiv'></div>

                    {EssayArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {ArticleArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>ဆောင်းပါး</p>
                    <div className='underlineDiv'></div>
                    {ArticleArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {StoryArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>ဝတ္ထုတို</p>
                    <div className='underlineDiv'></div>
                    {StoryArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {YoungArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>လူငယ်ဖတ်စာ အတိုအထွာ</p>
                    <div className='underlineDiv'></div>
                    {YoungArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {NewAuthorStoryArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>ပုရစ်ဖူးဝတ္ထုတို</p>
                    <div className='underlineDiv'></div>
                    {NewAuthorStoryArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {ThukumaArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>မရွှေနှင်းနှင့် စကားစမြည်</p>
                    <div className='underlineDiv'></div>
                    {ThukumaArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {FunnyArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>ရယ်စရာ</p>
                    <div className='underlineDiv'></div>
                    {FunnyArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
                {CartoonArray.length > 0 ? (
                  <div className='storyContainer'>
                    <p className='storyHeader'>ကာတွန်း</p>
                    <div className='underlineDiv'></div>
                    {CartoonArray.map((item, index) => {
                      return (
                        <button key={index} className='matikarItem'>
                          <img
                            src={`${imageBaseURL}${item.coverImage}`}
                            className='imgs'
                          />
                        </button>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthlyMagazine;
