import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import imageBaseURL from '../../Assets/common/imageBaseURL';
import baseURL from '../../Assets/common/baseUrl';
import BTalk_Verify_Badge from './../../Images/BTalk_Verified_Badge.png';
import CustomAlertModal from '../../Utils/CustomAlertModal';

//Css
import './../../Styles/posts.css';

const Post = (props) => {
  const video = React.useRef(null);

  const [IconShow, setIconShow] = useState(true);
  const [PostLikeCount, setPostLikeCount] = useState(0);
  const [Liked, setLiked] = useState();
  const [LikePress, setLikePress] = useState(true);
  const [PostCommentCount, setPostCommentCount] = useState(0);

  const userId = localStorage.getItem('userId');
  const isDarkMode = localStorage.getItem('isDarkMode');
  const isMM = localStorage.getItem('isMM');
  const userToken = localStorage.getItem('userToken');

  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [IsFollowing, setIsFollowing] = useState(false);
  const [IsFollower, setIsFollower] = useState(false);
  const [IsBlocker, setIsBlocker] = useState(false);
  const [IsBlocking, setIsBlocking] = useState(false);
  const [visible, setVisible] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetchPostLikesApi();
    CheckPostLikeApi();
    fetchFollowApi();
    fetchBlockApi();
    fetchPostCommentsCountApi();
    const interval = setInterval(() => {
      fetchPostLikesApi();
      fetchPostCommentsCountApi();
      fetchFollowApi();
      fetchBlockApi();
    }, 10000);
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(interval);
      setIconShow(true);
      window.removeEventListener('resize', handleResize);

      setIsFollowing(false);
      setIsFollower(false);
      setIsBlocker(false);
      setIsBlocking(false);
      setVisible(false);
    };
  }, []);

  let postId = props.post._id;

  const handleProgress = (event) => {
    const { loaded, total } = event.nativeEvent;
    setProgress((loaded / total) * 100);
  };
  const handleLoad = () => {
    setIsLoaded(true);
  };

  let poster = props.post.poster;

  // if (
  //   route.name == 'ProfileFromHome' ||
  //   route.name == 'ProfileFromMenu' ||
  //   route.name == 'ProfileFromChat' ||
  //   route.name == 'ProfileFromFriend' ||
  //   route.name == 'ProfileFromBook'
  // ) {
  //   poster = props.post.poster;
  // } else {
  //   poster = props.post.poster[0];
  //   // poster = props.post.poster;
  // }

  const description = props.post.description;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  };

  const handlePostMenu = () => {
    alert(
      isMM == 'true' ? 'ပို့စ်မီနူး' : 'Post Menu',
      isMM == 'true' ? 'ရွေးချယ်ပါ' : 'Select',
      [
        {
          text: isMM == 'true' ? 'နောက်သို့' : 'Go Back',
          onPress: setIconShow(!IconShow),
          style: 'cancel',
        },
        {
          text:
            poster._id == userId
              ? isMM == 'true'
                ? 'ပိုစ် ဖျက်မည်'
                : 'Delete Post'
              : isMM == 'true'
              ? 'တိုင်ကြားမည်'
              : 'Report',
          onPress: poster._id == userId ? onPressDelete : onPressReport,
        },
        {
          text:
            poster._id == userId
              ? null
              : isMM == 'true'
              ? 'ပိတ်ပင်မည်'
              : 'Block',
          onPress: poster._id == userId ? null : showDialog,
        },
      ]
    );
  };

  //Post Edit

  // const handleEditPost = () => {
  //   props.navigation.navigate('EditPostFromHome', {
  //     postEdit: props.post,
  //   });
  //   setIconShow(!IconShow);
  // };

  //Post Delete

  const onPressDelete = () => {
    alert(
      isMM == 'true' ? 'အတည်ပြုပေးပါ' : 'Please Confirm',
      isMM == 'true'
        ? 'ယခု ပို့စ်အား ဖျက်မည်လား'
        : 'Are you sure to delete this post?',
      [
        {
          text: isMM == 'true' ? 'မဟုတ်ပါ' : 'No',
          onPress: setIconShow(!IconShow),
          style: 'cancel',
        },
        { text: isMM == 'true' ? 'ဟုတ်ပါသည်' : 'Yes', onPress: postDelete },
      ]
    );
  };

  const onPressReport = () => {
    setIconShow(!IconShow);
    setModalInfo({
      isOpen: true,
      title: isMM == 'true' ? 'တိုင်ကြားနေပါသည်..' : 'Reporting...',
      message:
        isMM == 'true'
          ? 'ကျေးဇူးပြု၍ ခေတ္တစောင့်ပေးပါ'
          : 'Please wait a second',
      autoClose: true,
      duration: 3000,
    });
    const EmailData = {
      email: 'ceo@btalk.org',
      subject: `Post Report ${postId}`,
      message: `${userId} is reported the post. post id ${postId}`,
    };

    fetch(`${baseURL}emails/report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(EmailData),
    })
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'တိုင်ကြားမှု ပေးပို့ခြင်း အောင်မြင်ပါသည်'
                : 'Report sent successfully!',
            message:
              isMM == 'true'
                ? 'BTalk မှ စိစစ်ပြီး ဆောင်ရွက်ပေးပါမည်'
                : 'It will be moderated by BTalk',
            autoClose: true,
            duration: 3000,
          });
        } else if (res.status === 500) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true' ? 'ပေးပို့ခြင်း မအောင်မြင်ပါ။' : 'Sending Failed!',
            message:
              isMM == 'true'
                ? 'သင့်အစီရင်ခံစာကို ပေးပို့ရာတွင် တစ်ခုခုမှားသွားသည်။ ထပ်ကြိုးစားပါ။'
                : 'Something went wrong while sending your report. Try again.',
            autoClose: true,
            duration: 3000,
          });
        }
      })
      .catch((error) =>
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true' ? 'ပေးပို့ခြင်း မအောင်မြင်ပါ။' : 'Sending Failed!',
          message:
            isMM == 'true'
              ? 'သင့်အစီရင်ခံစာကို ပေးပို့ရာတွင် တစ်ခုခုမှားသွားသည်။ ထပ်ကြိုးစားပါ။'
              : 'Something went wrong while sending your report. Try again.',
          autoClose: true,
          duration: 3000,
        })
      );
  };

  const postDelete = async () => {
    setIconShow(!IconShow);
    setModalInfo({
      isOpen: true,
      title: isMM == 'true' ? 'ပို့စ်ဖျက်နေပါသည်' : 'Deleting...',
      message:
        isMM == 'true'
          ? 'ကျေးဇူးပြု၍ ခေတ္တစောင့်ပေးပါ'
          : 'Please wait a second',
      autoClose: true,
      duration: 3000,
    });

    await fetch(`${baseURL}posts/${postId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          setModalInfo({
            isOpen: true,
            title:
              isMM == 'true'
                ? 'ပို့စ်ဖျက်ခြင်း အောင်မြင်ပါသည်။'
                : 'Post was successfully deleted!',
            message:
              isMM == 'true'
                ? 'ပို့စ်အားဖယ်ရှားရန် ဖုန်းမျက်နာပြင်အား အပေါ်မှ အောက်သို့ ဆွဲပေးပါ'
                : 'To clear post scroll your screen up to down',
            autoClose: true,
            duration: 3000,
          });
          fetch(`${baseURL}postLikes/${postId}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          fetch(`${baseURL}postComments/${postId}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
        }
      })
      .catch((error) => {
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'တစ်စုံတစ်ခု မှားယွင်းနေပါသည်။'
              : 'Someting was wrong!',
          message:
            isMM == 'true'
              ? 'ကျေးဇူးပြု၍ ထပ်မံကြိုးစားပေးပါ။'
              : 'Please, try again!',
          autoClose: true,
          duration: 3000,
        });
      });
  };

  //Post Like

  const postLikeHandler = async () => {
    if (
      userId === 'Guest' ||
      userId === null ||
      userId === 'null' ||
      userId.length < 6
    ) {
      return handleGuest();
    } else {
      if (LikePress) {
        setLikePress(false);
        try {
          const res = await fetch(
            `${baseURL}postLikes/checkpd/${postId}/${userId}`
          );
          const data = await res.json();
          setLiked(data.value);
          fetchPostLikesApi();
          setLikePress(true);
        } catch (error) {
          setLikePress(true);
        }
      } else {
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'ပထမ လုပ်ဆောင်ချက် မပြီးသေးပါ။'
              : 'First action is not complete ',
          message:
            isMM == 'true'
              ? 'ခေတ္တစောင့်ပြီးမှ ထပ်မံကြိုးစားပေးပါ။'
              : 'Please, try again later!',
          autoClose: true,
          duration: 3000,
        });
      }
    }
  };

  const fetchPostLikesApi = async () => {
    try {
      const res = await fetch(`${baseURL}postLikes/count/${postId}`);
      const data = await res.json();
      setPostLikeCount(data.postLikeCount);
    } catch (error) {}
  };

  const CheckPostLikeApi = async () => {
    if (
      userId === 'Guest' ||
      userId === null ||
      userId === 'null' ||
      userId.length < 6
    ) {
      return;
    }
    try {
      const res = await fetch(`${baseURL}postLikes/check/${postId}/${userId}`);
      const data = await res.json();
      setLiked(data.postLikeCount);
    } catch (error) {}
  };

  //Post Comment
  const fetchPostCommentsCountApi = async () => {
    try {
      const res = await fetch(`${baseURL}postComments/count/${postId}`);
      const data = await res.json();
      setPostCommentCount(data.postCommentCount);
    } catch (error) {}
  };

  //Post Share
  // const postShare = () => {
  //   Toast.show({
  //     type: ALERT_TYPE.DANGER,
  //     title: isMM ? 'တောင်းပန်ပါတယ်။' : 'Sorry!',
  //     textBody: isMM
  //       ? 'ယခုလုပ်ဆောင်ချက်အား လက်တလော အသုံးမပြုနိုင်သေးပါ။'
  //       : 'This feature con not use right now',
  //     autoClose: true,
  //     button: 'Ok',
  //   });
  // };

  const fetchFollowApi = async () => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return;
    }
    await fetch(`${baseURL}follows/getfollowOne/${userId}/${poster._id}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    }).then(async (res) => {
      const data = await res.json();
      setIsFollower(data.Follower);
      setIsFollowing(data.Following);
    });
  };

  const fetchBlockApi = async () => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return;
    }
    await fetch(`${baseURL}blocks/getBlockOne/${userId}/${poster._id}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    }).then(async (res) => {
      const data = await res.json();
      setIsBlocker(data.Blocker);
      setIsBlocking(data.Blocking);
    });
  };

  const handleFollow = async () => {
    if (
      userId === 'Guest' ||
      userId === null ||
      userId === 'null' ||
      userId.length < 6
    ) {
      return;
    }
    if (IsFollowing) {
      await fetch(`${baseURL}follows/unfollowing/${userId}/${poster._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        if (res.status == 200 || res.status == 304) {
          fetchFollowApi();
        }
      });
    } else {
      fetch(`${baseURL}follows/following/${userId}/${poster._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        if (res.status == 200 || res.status == 201) {
          fetchFollowApi();
        }
      });
    }
  };

  const showDialog = () => setVisible(true);
  const handleCancel = () => setVisible(false);
  const handleBlock = async () => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return;
    }
    setVisible(false);
    fetch(`${baseURL}blocks/blocking/${userId}/${poster._id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }).then((res) => {
      if (res.status == 200 || res.status == 201) {
        // Toast.show({
        //   type: ALERT_TYPE.SUCCESS,
        //   title: isMM
        //     ? 'ပိတ်ပင်ခြင်း အောင်မြင်ပါသည်။'
        //     : 'User was successfully Blocked!',
        //   textBody: isMM
        //     ? 'ပို့စ်အားဖယ်ရှားရန် ဖုန်းမျက်နာပြင်အား အပေါ်မှ အောက်သို့ ဆွဲပေးပါ'
        //     : 'To clear post scroll your screen up to down',
        //   autoClose: true,
        //   button: 'Ok',
        // });
      }
    });
  };

  const goToSinglePost = () => {
    // if (route.name == 'Profile') {
    //   props.navigation.navigate('SinglePostFromProfile', { post: props.post });
    // } else {
    //   props.navigation.navigate('SinglePostFromHome', { post: props.post });
    // }
  };
  const goToSingleImages = () => {
    // if (route.name == 'Profile') {
    //   props.navigation.navigate('SingleImagesFromProfile', {
    //     singleimages: props.post.images,
    //   });
    // } else {
    //   props.navigation.navigate('SingleImagesFromHome', {
    //     singleimages: props.post.images,
    //   });
    // }
  };

  const getGridStyle = (count, index) => {
    switch (count) {
      case 1:
        return { flex: 1 };
      case 2:
        return { flex: 0.5, width: '10%' };
      case 3:
        if (index == 0) {
          return { width: '90%', flex: 1, height: 'auto' };
        } else {
          return { width: '30%', flex: 0.5, height: 'auto' };
        }
      case 4:
        return { flex: 0.5, minWidth: '45%', height: 'auto' };
      default:
        if (index == 0 || index == 1) {
          return { flex: 0.5, minWidth: '45%', height: 'auto' };
        } else {
          return { minWidth: '30%', flex: 0.5, height: 'auto' };
        }
    }
  };

  function renderImages(images) {
    return (
      <div
        style={{
          backgroundColor: isDarkMode == 'true' ? '#222' : '#f0f0f0',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'center',
          maxHeight: '100vh',
          display: 'flex',
          overflow: 'hidden',
          // backgroundColor: 'black',
        }}
      >
        {images.slice(0, 5).map((img, index) => (
          <div
            key={index}
            style={{
              shadowColor: isDarkMode == 'true' ? '#fff' : '#000',
              shadowOffset: {
                width: 0.5,
                height: 0.5,
              },
              shadowOpacity: 0.5,
              elevation: 10,
              backgroundColor: isDarkMode == 'true' ? '#222' : '#f0f0f0',
              position: index === 4 && images.length > 5 ? 'relative' : null,
              width:
                images.length === 1
                  ? '100%'
                  : images.length === 2
                  ? '49%'
                  : images.length === 3 && index === 0
                  ? '100%'
                  : images.length === 3
                  ? '49%'
                  : index === 0 || index === 1
                  ? '49.5%'
                  : '32.5%',
              padding: 1,
            }}
            // getGridStyle(images.length, index)
            // index === 4 && images.length > 5 ? { position: 'relative' } : {}),
          >
            {images.length < 2 ? (
              <img
                src={`${imageBaseURL}${img}`}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <div>
                <img
                  src={`${imageBaseURL}${img}`}
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
            )}

            <div style={Style.imageGrid}></div>
            {index === 4 && images.length > 5 && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                <p
                  style={{
                    color: 'white',
                    fontSize: 20,
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  +{images.length - 4}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  //handle Guest

  const handleGuest = () => {
    // Dialog.show({
    //   type: ALERT_TYPE.WARNING,
    //   title: isMM ? 'ဧည့်သည်အကောင့်' : 'Guset Account',
    //   textBody: isMM
    //     ? 'ဧည့်သည်အကောင့်သည် ဤဝန်ဆောင်မှုကို အသုံးပြု၍မရပါ။ ကျေးဇူးပြု၍ ဦးစွာ အကောင့်ဖွင့်ပါ။'
    //     : 'Guest account can not access this feature. Please register first.',
    //   autoClose: 3000,
    //   button: 'OK',
    // });
  };

  const handleClose = () => {
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <div
      style={{
        width: width < 900 ? '90%' : '50%',
        margin: 'auto',
        marginTop: 15,
        marginBottom: 10,
      }}
    >
      <CustomAlertModal
        isOpen={modalInfo.isOpen}
        onClose={handleClose}
        title={modalInfo.title}
        message={modalInfo.message}
        autoClose={modalInfo.autoClose}
        duration={modalInfo.duration}
      />
      <div
        style={{
          shadowColor: isDarkMode == 'true' ? '#fff' : '#000',
          backgroundColor: isDarkMode == 'true' ? '#000' : '#fff',
          // width: '90%',
          borderRadius: 15,
          padding: 10,
          marginVertical: 3,
          elevation: 20,
          shadowColor: '#000',
          shadowOffset: {
            width: 1,
            height: 1,
          },
          shadowOpacity: 0.5,
        }}
      >
        {/* <DialogRN.Container visible={visible}>
          <DialogRN.Title>{isMM ? 'ပိတ်ပင်ခြင်း' : 'Blocking'}</DialogRN.Title>
          <DialogRN.Description>
            {isMM
              ? `${poster.name} အကောင့်အား ပိတ်ပင်မှာ သေချာပါသလား`
              : `Are you sure you want to block ${poster.name}?`}
          </DialogRN.Description>
          <DialogRN.Button
            label={isMM ? 'မဟုတ်ပါ' : 'No'}
            onPress={handleCancel}
          />
          <DialogRN.Button
            label={isMM ? 'ဟုတ်ပါသည်' : 'Yes'}
            onPress={handleBlock}
          />
        </DialogRN.Container> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <button
              style={{
                backgroundColor: 'inherit',
                borderWidth: 0,
                marginTop: 10,
              }}
            >
              {poster === undefined ||
              poster ===
              (
                // <FontAwesome5Icon
                //   name='user'
                //   color={isDarkMode ? '#FFDe2e' : '#333'}
                //   size={30}

                // />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={30}
                  viewBox='0 0 448 512'
                  style={{
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderRadius: 100,
                    marginRight: 10,
                    borderColor: 'gainsboro',
                    borderWidth: 1,
                  }}
                >
                  <path
                    fill={isDarkMode ? '#FFDe2e' : '#333'}
                    d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
                  />
                </svg>
              ) ? null : poster.profilePicture !== '' ||
                poster.profilePicture == undefined ? (
                <img
                  style={{
                    borderRadius: 100,
                    marginRight: 10,
                    borderColor: 'gainsboro',
                    borderWidth: 1,
                    marginLeft: -5,
                    width: 50,
                    height: 50,
                  }}
                  src={`${imageBaseURL}${poster.profilePicture}`}
                />
              ) : (
                // <FontAwesome5Icon
                //   name='user'
                //   color={isDarkMode ? '#FFDe2e' : '#333'}
                //   size={30}

                // />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={30}
                  viewBox='0 0 448 512'
                  style={{
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderRadius: 100,
                    marginRight: 10,
                    borderColor: 'gainsboro',
                    borderWidth: 1,
                  }}
                >
                  <path
                    fill={isDarkMode == 'true' ? '#FFDe2e' : '#333'}
                    d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
                  />
                </svg>
              )}
            </button>
            <div style={{}}>
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  backgroundColor: 'inherit',
                  borderWidth: 0,
                  height: 15,
                }}
                // onClick={() =>
                //   props.navigation.navigate('ProfileRoute', {
                //     screen: 'Profile',
                //     params: {
                //       poster: poster._id,
                //     },
                //   })
                // }
              >
                <p
                  style={{
                    color: isDarkMode == 'true' ? '#fff' : '#000',
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  {poster ? poster.name : null}
                </p>
                {poster.verifiedUser ? (
                  <div
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    <img
                      src={BTalk_Verify_Badge}
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                ) : null}
              </button>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  height: 13,
                }}
              >
                <p
                  style={{
                    color: isDarkMode == 'true' ? '#fff' : '#000',
                    fontSize: 12,
                  }}
                >
                  {moment(props.post.dateCreated).fromNow()}
                </p>
                <p
                  style={{
                    color: isDarkMode == 'true' ? '#fff' : '#000',
                    fontSize: 12,
                  }}
                >
                  . Public
                </p>
              </div>
            </div>
          </div>

          <div>
            <button
              style={{ backgroundColor: 'inherit', borderWidth: 0 }}
              // onClick={() =>
              //   setIconShow(!IconShow), handlePostMenu();
              // }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={30}
                viewBox='0 0 448 512'
              >
                <path
                  fill={isDarkMode ? '#ffde2e' : '#333'}
                  d='M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z'
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          style={{
            marginTop: 15,
            display: 'flex',
            alignContent: 'center',
            margin: 'auto',
          }}
        >
          <div>
            <button
              style={{
                backgroundColor: 'inherit',
                borderWidth: 0,
              }}
              onClick={() => goToSinglePost()}
            >
              <p
                selectable='true'
                style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}
              >
                {description.length > 500
                  ? description.substring(0, 250) +
                    `${isMM ? '  ...ပိုမိုကြည့်ရှုရန်' : '  ...See more'}
                `
                  : description}
              </p>
            </button>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                width: '90%',
                backgroundColor: 'red',
              }}
            >
              {props.post.images == '' && props.post.image == '' ? (
                props.post.video == '' || props.post.video == null ? null : (
                  <div
                    style={{
                      display: 'flex',
                      // alignSelf: 'center',
                      marginTop: 15,
                      // width: '90%',
                      // margin: 'auto',
                      // alignItems: 'center',
                      // justifyContent: 'center',
                      backgroundColor: '#fbfbfb',
                      zIndex: 4,
                      overflow: 'hidden',
                    }}
                  >
                    <video
                      ref={video}
                      // style={{ width: width, height: videoHeight }}
                      style={{ width: '100%', height: 500 }}
                      src={{ uri: `${imageBaseURL}${props.post.video}` }}
                      // useNativeControls
                      controls
                      resizeMode='contain'
                      // shouldPlay
                      rate={1.0}
                    />
                  </div>
                )
              ) : (
                <button
                  style={{
                    width: '100%',
                    margin: 'auto',
                    zIndex: 4,
                    // overflow: 'hidden',
                    backgroundColor: 'inherit',
                    borderWidth: 0,
                  }}
                  onClick={goToSingleImages}
                >
                  {renderImages(props.post.images)}
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
              marginTop: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <p style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}>
              {PostLikeCount > 1
                ? `${PostLikeCount} Likes`
                : `${PostLikeCount} Like`}
            </p>
            <p style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}>
              {PostCommentCount > 1
                ? `${PostCommentCount} Comments`
                : `${PostCommentCount} Comment`}
            </p>
            <p style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}>
              0 Share
            </p>
          </div>
          <div
            style={{ backgroundColor: 'gainsboro', height: 1, marginBottom: 5 }}
          ></div>
          <div
            style={{
              display: 'flex',
              paddingLeft: 10,
              paddingRight: 10,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <button
              style={{
                backgroundColor: 'inherit',
                borderWidth: 0,
                cursor: 'pointer',
              }}
              onClick={postLikeHandler}
            >
              {/* <FontAwesome5Icon
                name='thumbs-up'
                size={25}
                color=
                solid={Liked < 1 ? false : true}
              /> */}
              {Liked ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={25}
                  viewBox='0 0 512 512'
                >
                  <path
                    fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                    d='M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  width={25}
                >
                  <path
                    fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                    d='M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z'
                  />
                </svg>
              )}
            </button>
            <button
              style={{
                backgroundColor: 'inherit',
                borderWidth: 0,
                cursor: 'pointer',
              }}
              onClick={() => {
                goToSinglePost();
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={25}
                viewBox='0 0 512 512'
              >
                <path
                  fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                  d='M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z'
                />
              </svg>
            </button>
            {/* <TouchableOpacity onPress={postShare}>
            <FontAwesome5Icon name='share-square' size={25} color='gainsboro' />
          </TouchableOpacity> */}
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Style = {
  darkContainerBGC: { backgroundColor: '#000' },
  lightContainerBGC: { backgroundColor: '#fff' },
  container: {
    width: '90%',
    borderRadius: 15,
    // paddingVertical: 10,
    // paddingHorizontal: 15,
    padding: 10,
    marginVertical: 3,
    elevation: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.5,
    // maxHeight: height,
    // overflow: 'hidden'
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  postContainer: {
    marginTop: 15,
  },

  iconShow: {
    display: 'flex',
  },
  iconHide: {
    display: 'none',
  },
  menu: {
    position: 'absolute',
    right: 0,
    top: -20,
    borderColor: 'gainsboro',
    borderWidth: 1,
    borderRadius: 8,
    elevation: 10,
    paddingVertical: 5,
    paddingHorizontal: 20,
    zIndex: 20,
    shadowColor: '#555',
    shadowOpacity: 0.7,
    shadowOffset: {
      height: 1,
      width: 2,
    },
  },
  text1: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingVertical: 5,
  },
  posterPP: {
    marginRight: 10,
    borderColor: 'gainsboro',
    borderWidth: 1,
    marginLeft: -5,
    width: 50,
    height: 50,
  },
  posterIcon: {
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderRadius: 100,
    marginRight: 10,
    borderColor: 'gainsboro',
    borderWidth: 1,
  },

  darkTextColor: {
    color: '#fff',
    // color: '#ffde2e',
  },
  lightTextColor: {
    color: '#333',
  },

  posterName: {
    fontWeight: 'bold',
    fontSize: 18,
  },

  backArrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  bottomConatiner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'gainsboro',
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginTop: 10,
  },

  //Model
  centereddiv: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  modaldiv: {
    borderRadius: 20,
    paddingVertical: 10,
    alignItems: 'center',
    shadowColor: 'white',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 5,
    maxHeight: '100vh',
    width: '95%',
    paddingHorizontal: 10,
  },
  modalDarkdivBGC: { backgroundColor: '#000' },
  modalLightdivBGC: { backgroundColor: '#fff' },

  modalHeaderContainer: {
    width: '95%',
    borderBottomColor: 'gainsboro',
    borderBottomWidth: 1,
    height: 40,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -10,
  },
  commentContainer: {
    minHeight: '400vh',
    width: '95%',
    marginTop: 10,
  },
  loadingConatiner: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    height: '100vh',
  },
  cmDataContainer: {
    flexDirection: 'row',
    marginBottom: 15,
  },
  commenterPP: {
    borderRadius: 100,
    marginRight: 10,
    borderColor: '#ffde2e',
    borderWidth: 1,
    marginLeft: -5,
  },
  commenterPPIcon: {
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderRadius: 100,
    marginRight: 10,
    borderColor: '#ffde2e',
    borderWidth: 1,
  },
  cmItemContainer: {
    marginLeft: 5,
    elevation: 20,
    borderColor: 'gainsboro',
    borderWidth: 1,
    borderRadius: 15,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  cmData: {
    marginTop: 5,
    maxWidth: '80%',
  },
  cmInputC: {
    width: '90%',
    marginTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cmInput: {
    height: 40,
    paddingLeft: 10,
    borderRadius: 20,
    borderWidth: 1,
    width: '85%',
  },

  //Grid Image
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxHeight: '100vh',
    // overflow: 'hidden',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  overlayContainer: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  overlayText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
  },
};

export default Post;
