import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = ({ navigation }) => {
  const isDarkMode = localStorage.getItem('isDarkMode');
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: isDarkMode == 'true' ? '#000' : '#fff' }}>
      <div style={{ paddingHorizontal: 20, paddingBottom: 50 }}>
        <button
          onClick={() => navigate(-1)}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: 40,
            marginTop: 20,
            border: 0,
            cursor: 'pointer',
            backgroundColor: 'inherit',
            display: 'flex',
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 320 512'
            width={20}
          >
            <path
              fill={isDarkMode == 'true' ? '#f0f0f0' : '#222'}
              d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
            />
          </svg>

          <p
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: isDarkMode == 'true' ? '#fff' : '#000',
              marginLeft: 5,
            }}
          >
            Go Back
          </p>
        </button>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Privacy Policy for BTalk
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Privacy Policy
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Last updated: January 11, 2024 This Privacy Policy describes Our
          policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy
          rights and how the law protects You. We use Your Personal data to
          provide and improve the Service. By using the Service, You agree to
          the collection and use of information in accordance with this Privacy
          Policy.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Interpretation and Definitions Interpretation
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Definitions
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          For the purposes of this Privacy Policy:
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Account means a unique account created for You to access our Service
          or parts of our Service.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Affiliate means an entity that controls, is controlled by or is under
          common control with a party, where "control" means ownership of 50% or
          more of the shares, equity interest or other securities entitled to
          vote for election of directors or other managing authority.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Application refers to BTalk, the software program provided by the
          Company.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Company (referred to as either "the Company", "We", "Us" or "Our" in
          this Agreement) refers to BTalk.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Country refers to: Thailand
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Device means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Personal Data is any information that relates to an identified or
          identifiable individual.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Service refers to the Application.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Service Provider means any natural or legal person who processes the
          data on behalf of the Company. It refers to third-party companies or
          individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the
          Service is used.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Usage Data refers to data collected automatically, either generated by
          the use of the Service or from the Service infrastructure itself (for
          example, the duration of a page visit).
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          You means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Collecting and Using Your Personal Data
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Types of Data Collected
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Personal Data
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Usage Data is collected automatically when using the Service.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Information Collected while Using the Application
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Pictures and other information from your Device's camera and photo
          library We use this information to provide features of Our Service, to
          improve and customize Our Service. The information may be uploaded to
          the Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Use of Your Personal Data
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The Company may use Personal Data for the following purposes:
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          To manage Your requests: To attend and manage Your requests to Us.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          For business transfers: We may use Your information to evaluate or
          conduct a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by Us about our
          Service users is among the assets transferred.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          For other purposes: We may use Your information for other purposes,
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns and to evaluate and improve
          our Service, products, services, marketing and your experience.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          We may share Your personal information in the following situations:
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          contact You.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          For business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of Our business to another company.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          With business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          With Your consent: We may disclose Your personal information for any
          other purpose with Your consent.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Retention of Your Personal Data
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Transfer of Your Personal Data
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Delete Your Personal Data
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Disclosure of Your Personal Data
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Business Transactions
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Law enforcement
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Other legal requirements
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <p
          style={{
            fontSize: 15,

            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Comply with a legal obligation
        </p>
        <p
          style={{
            fontSize: 15,

            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Protect and defend the rights or property of the Company
        </p>
        <p
          style={{
            fontSize: 15,

            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p
          style={{
            fontSize: 15,

            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Protect the personal safety of Users of the Service or the public
        </p>
        <p
          style={{
            fontSize: 15,

            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Protect against legal liability
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Security of Your Personal Data
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Children's Privacy
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          User-Generated Content
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Introduction
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          BTalk is committed to protecting the privacy and security of our
          users. This section of the End User License Agreement (EULA)
          specifically addresses our policies and practices concerning the
          user-generated content (UGC) on our platform.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          What Constitutes User-Generated Content
        </p>
        <p
          style={{
            fontSize: 15,
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 10,
          }}
        >
          User-Generated Content refers to any content created and shared by
          users within the BTalk app. This includes, but is not limited to, text
          messages, images, videos, and audio recordings.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Collection of User-Generated Content
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          BTalk collects and stores UGC as part of our service offering. This
          collection is essential for the operation of the app, enabling user
          interaction, content sharing, and community building.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Use of User-Generated Content
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Purpose: UGC is primarily used to facilitate communication and
          interaction within the BTalk community.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Sharing: We do not share UGC with third parties without user consent,
          except as required by law or as necessary to protect our services and
          users.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Moderation: BTalk reserves the right to moderate UGC to ensure
          compliance with our content policies and community guidelines.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          User Responsibilities
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Content Ownership: Users retain ownership of their UGC, but grant
          BTalk a license to host, distribute, and display their content within
          the app.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Appropriate Content: Users must ensure that their UGC does not violate
          any applicable laws or our community standards. Prohibited content
          includes hate speech, harassment, and illegal activities.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Data Security
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Protection Measures: We implement a variety of security measures to
          maintain the safety of your personal information and UGC.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          User Control: Users have control over their UGC and may edit or delete
          their content at their discretion.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Links to Other Websites
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Changes to this Privacy Policy
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: isDarkMode == 'true' ? '#fff' : '#000',
            marginTop: 20,
          }}
        >
          Contact Us
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <p
          style={{
            fontSize: 15,
            marginTop: 10,
            color: isDarkMode == 'true' ? '#fff' : '#000',
          }}
        >
          By email: ceo@btalk.org
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
