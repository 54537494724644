import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import imageBaseURL from '../../../Assets/common/imageBaseURL';
import baseURL from '../../../Assets/common/baseUrl';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomAlertModal from '../../../Utils/CustomAlertModal';

import BTalk_Verify_Badge from './../../../Images/BTalk_Verified_Badge.png';

import './SingleMagazineContent.css';

const SingleMagazineContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const contentid = queryParams.get('contentid');
  const type = queryParams.get('type');
  const magazineId = queryParams.get('magazineid');

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navStyle, setNavStyle] = useState({ top: '0px' });

  const isDarkMode = localStorage.getItem('isDarkMode');
  const userId = localStorage.getItem('userId');
  const isMM = localStorage.getItem('isMM');
  const userToken = localStorage.getItem('userToken');

  const [SingleContent, setSingleContent] = useState('');
  const [Loading, setLoading] = useState(true);
  const [IconShow, setIconShow] = useState(true);
  const [PostLikeCount, setPostLikeCount] = useState(0);
  const [Liked, setLiked] = useState();
  const [LikePress, setLikePress] = useState(true);
  const [PostCommentCount, setPostCommentCount] = useState(0);
  const [CommentData, setCommentData] = useState('');
  const [PostComment, setPostComment] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  const fetchSingleContent = async () => {
    const res = await fetch(
      `${baseURL}magazines/singlecontent/${type}/${contentid}`
    );
    if (res.status === 400) {
      navigate('*');
    }
    const data = await res.json();
    setSingleContent(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSingleContent();
    fetchPostLikesApi();
    CheckPostLikeApi();
    fetchPostCommentsApi();
    fetchPostCommentsCountApi();
    const interval = setInterval(() => {
      fetchPostLikesApi();
      fetchPostCommentsApi();
      fetchPostCommentsCountApi();
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, [navigate, contentid, type]);

  const postLikeHandler = async () => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return handleGuest();
    } else {
      if (LikePress) {
        setLikePress(false);
        try {
          const res = await fetch(
            `${baseURL}postLikes/checkpd/${contentid}/${userId}`
          );
          const data = await res.json();
          setLiked(data.value);
          fetchPostLikesApi();
          setLikePress(true);
        } catch (error) {
          setLikePress(true);
        }
      } else {
        return setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'ပထမ လုပ်ဆောင်ချက် မပြီးသေးပါ။'
              : 'First action is not complete!',
          message:
            isMM == 'true'
              ? 'ခေတ္တစောင့်ပြီးမှ ထပ်မံကြိုးစားပေးပါ။'
              : 'Try again later',
          autoClose: true,
          duration: 3000,
        });
      }
    }
  };

  const fetchPostLikesApi = async () => {
    try {
      const res = await fetch(`${baseURL}postLikes/count/${contentid}`);
      const data = await res.json();
      setPostLikeCount(data.postLikeCount);
    } catch (error) {}
  };

  const CheckPostLikeApi = async () => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return handleGuest();
    } else {
      try {
        const res = await fetch(
          `${baseURL}postLikes/check/${contentid}/${userId}`
        );
        const data = await res.json();
        setLiked(data.postLikeCount);
      } catch (error) {}
    }
  };

  //Post Comment
  const fetchPostCommentsCountApi = async () => {
    try {
      const res = await fetch(`${baseURL}postComments/count/${contentid}`);
      const data = await res.json();
      setPostCommentCount(data.postCommentCount);
    } catch (error) {}
  };

  const fetchPostCommentsApi = async () => {
    try {
      const res = await fetch(`${baseURL}postComments/${contentid}`);
      const data = await res.json();
      setPostComment(data);
      setLoading(false);
    } catch (error) {}
  };

  const postCommentHandler = async () => {
    if (
      userId === null ||
      userId === 'null' ||
      userId == undefined ||
      userId === 'Guest' ||
      userId.length < 5
    ) {
      return handleGuest();
    } else {
      if (CommentData == '' || CommentData == null) {
        setModalInfo({
          isOpen: true,
          title:
            isMM == 'true'
              ? 'မှတ်ချက်ဒေတာ လိုအပ်သည်။'
              : 'Comment Data Required!',
          message: '',
          autoClose: true,
          duration: 3000,
        });
      } else {
        setModalInfo({
          isOpen: true,
          title: isMM == 'true' ? 'လုပ်ဆောင်နေပါသည်။' : 'Loading...',
          message:
            isMM == 'true'
              ? 'ကျေးဇူးပြူ၍ ခေတ္တစောင့်ပေးပါ'
              : 'Please, wait a few seconds',
          autoClose: true,
          duration: 3000,
        });

        const Data = {
          commenter: userId,
          data: CommentData,
        };

        fetch(`${baseURL}postComments/${contentid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(Data),
        })
          .then((res) => {
            setModalInfo({
              isOpen: true,
              title:
                isMM == 'true'
                  ? 'မှတ်ချက်ပေးခြင်းအောင်မြင်ပါသည်။'
                  : 'successfully commented',
              message: '',
              autoClose: true,
              duration: 3000,
            });
          })
          .catch((error) => {});
        setCommentData('');
      }
    }
  };

  const handleClose = () => {
    setModalInfo((prev) => ({ ...prev, isOpen: false }));
  };

  const handleGuest = () => {
    setModalInfo({
      isOpen: true,
      title: isMM == 'true' ? 'ဧည့်သည်အကောင့်' : 'Guset Account',
      message:
        isMM == 'true'
          ? 'ဧည့်သည်အကောင့်သည် ဤဝန်ဆောင်မှုကို အသုံးပြု၍မရပါ။ ကျေးဇူးပြု၍ ဦးစွာ အကောင့်ဖွင့်ပါ။'
          : 'Guest account can not access this feature. Please register first.',
      autoClose: true,
      duration: 3000,
    });
  };

  return (
    <div>
      <CustomAlertModal
        isOpen={modalInfo.isOpen}
        onClose={handleClose}
        title={modalInfo.title}
        message={modalInfo.message}
        autoClose={modalInfo.autoClose}
        duration={modalInfo.duration}
      />
      {Loading ? (
        <div className='flexCenter'>
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          <div className='monthlynav' style={navStyle}>
            <div
              style={{
                height: 70,
                backgroundColor: isDarkMode == 'true' ? '#000' : '#fff',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 20,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                onClick={() =>
                  navigate(`/btalkmagazine?magazineid=${magazineId}`)
                }
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor: 'inherit',
                  cursor: 'pointer',
                  border: 0,
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 320 512'
                  width={25}
                >
                  <path
                    fill={isDarkMode == 'true' ? '#fff' : '#000'}
                    d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
                  />
                </svg>
                <p
                  style={{
                    color: isDarkMode == 'true' ? '#fff' : '#333',
                    fontSize: 15,
                    marginLeft: 5,
                  }}
                >
                  {isMM ? 'နောက်သို့' : 'Go Back'}
                </p>
              </button>
              <div>
                <p
                  className='magazineTitle'
                  style={{ color: isDarkMode == 'true' ? '#fff' : '#000' }}
                >
                  {SingleContent.authorname} - {SingleContent.title}
                </p>
              </div>
              <div> </div>
            </div>
          </div>
          <div style={{ marginTop: 80 }}>
            <div>
              <div
                className={
                  isDarkMode == 'true'
                    ? 'container darkContainerBGC'
                    : 'container lightContainerBGC'
                }
              >
                {/* {SingleContent.poster == userId ? (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      alignSelf: 'flex-end',
                      paddingRight: 20,
                      paddingTop: 10,
                    }}
                  >
                    <button onClick={() => setIconShow(!IconShow)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                        width={30}
                      >
                        <path
                          fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                          d='M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z'
                        />
                      </svg>
                    </button>
                    <div
                      className={[
                        IconShow ? 'iconHide' : 'iconShow menu',
                        isDarkMode == 'true'
                          ? 'darkContainerBGC'
                          : 'lightContainerBGC',
                      ]}
                    >
                      <button
                        onClick={() => setIconShow(!IconShow)}
                        className='backArrow'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 320 512'
                          width={30}
                        >
                          <path
                            fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                            d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
                          />
                        </svg>
                        <p
                          className={
                            isDarkMode == 'true'
                              ? 'darkTextColor'
                              : 'lightTextColor'
                          }
                        >
                          {isMM == 'true' ? 'နောက်သို့' : 'Go Back'}
                        </p>
                      </button>
                      <button>
                        <p
                          className={
                            isDarkMode == 'true'
                              ? 'text1 darkTextColor'
                              : 'text1 lightTextColor'
                          }
                        >
                          {isMM == 'true' ? 'ဖျက်မည်' : 'Delete'}
                        </p>
                      </button>
                      <button
                        onClick={() => {
                          // props.navigation.navigate('EditPostFromHome', {
                          //   postEdit: post,
                          // });
                          setIconShow(!IconShow);
                        }}
                      >
                        <p
                          className={
                            isDarkMode == 'true'
                              ? 'text1 darkTextColor'
                              : 'text1 lightTextColor'
                          }
                        >
                          {isMM ? 'ပြုပြင်မည်' : 'Edit'}
                        </p>
                      </button>
                    </div>
                  </div>
                ) : null} */}

                <div style={{ alignItems: 'center', width: '100%' }}>
                  <img
                    src={`${imageBaseURL}${SingleContent.coverImage}`}
                    width={'100%'}
                    style={{ borderRadius: 12 }}
                  />

                  {SingleContent.description == 'null' ? null : (
                    <p
                      style={{
                        marginVertical: 20,
                        paddingHorizontal: 5,
                      }}
                      className={
                        isDarkMode == 'true'
                          ? 'darkTextColor'
                          : 'lightTextColor'
                      }
                    >
                      {SingleContent.description}
                    </p>
                  )}
                </div>

                <div>
                  <div className='bottomConatiner'>
                    <p
                      className={
                        isDarkMode == 'true'
                          ? 'darkTextColor'
                          : 'lightTextColor'
                      }
                    >
                      {PostLikeCount > 1
                        ? `${PostLikeCount} Likes`
                        : `${PostLikeCount} Like`}
                    </p>
                  </div>
                  <div
                    style={{
                      marginVertical: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingHorizontal: 20,
                    }}
                  >
                    <button className='likebtn' onClick={postLikeHandler}>
                      {Liked < 1 ? (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          width={25}
                        >
                          <path
                            fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                            d='M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z'
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          width={25}
                        >
                          <path
                            fill={isDarkMode == 'true' ? '#ffde2e' : '#333'}
                            d='M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z'
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className='commentView'>
                <div
                  className={
                    isDarkMode == 'true'
                      ? 'darkContainerBGC modalView'
                      : 'lightContainerBGC modalView'
                  }
                >
                  <div style={{ alignItems: 'center' }}>
                    <div className={'commentContainer'}>
                      <p
                        style={{ marginLeft: 20, marginBottom: 20 }}
                        className={
                          isDarkMode == 'true'
                            ? 'darkTextColor'
                            : 'lightTextColor'
                        }
                      >
                        {PostCommentCount > 1
                          ? `${PostCommentCount} Comments`
                          : `${PostCommentCount} Comment`}
                      </p>

                      {PostComment.map((postCommet, index) => (
                        <div key={index} className={'cmDataContainer'}>
                          <button
                            style={{
                              flexDirection: 'row',
                              paddingLeft: 10,
                            }}
                            className='btn1 likebtn '
                            onClick={() => {
                              // props.navigation.navigate('ProfileFromBook', {
                              //   poster: postCommet.commenter._id,
                              // });
                            }}
                          >
                            {postCommet.commenter.profilePicture !== '' ? (
                              <img
                                className={'commenterPP'}
                                src={`${imageBaseURL}${postCommet.commenter.profilePicture}`}
                              />
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 448 512'
                                className='commenterPPIcon'
                                width={30}
                              >
                                <path
                                  fill={
                                    isDarkMode == 'true' ? '#FFDe2e' : '#333'
                                  }
                                  d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
                                />
                              </svg>
                            )}
                          </button>
                          <div
                            className={
                              isDarkMode == 'true'
                                ? 'modalDarkViewBGC cmItemContainer'
                                : 'modalLightViewBGC cmItemContainer'
                            }
                          >
                            <button
                              style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              className='btn1 likebtn '
                              // onClick={() =>
                              //   props.navigation.navigate('ProfileRoute', {
                              //     screen: 'Profile',
                              //     params: {
                              //       poster: postCommet.commenter._id,
                              //     },
                              //   })
                              // }
                            >
                              <p
                                className={
                                  isDarkMode == 'true'
                                    ? 'darkTextColor posterName'
                                    : 'lightTextColor posterName'
                                }
                              >
                                {postCommet.commenter
                                  ? postCommet.commenter.name
                                  : null}
                              </p>
                              {postCommet.commenter.verifiedUser ? (
                                <div
                                  style={{
                                    marginLeft: 5,
                                  }}
                                >
                                  <img
                                    src={BTalk_Verify_Badge}
                                    style={{ width: 25, height: 25 }}
                                  />
                                </div>
                              ) : null}
                            </button>
                            <p
                              className={
                                isDarkMode == 'true'
                                  ? 'darkTextColor cmData'
                                  : 'lightTextColor cmData'
                              }
                            >
                              {postCommet.commentData}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                margin: 'auto',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '80%',
                paddingTop: 10,
                display: 'flex',
                paddingHorizontal: 50,
                marginBottom: 100,
              }}
            >
              <div style={{ width: '80%' }}>
                <textarea
                  style={{
                    color: isDarkMode == 'true' ? '#fff' : '#000',
                    minHeight: 40,
                    maxHeight: 100,
                    minHeight: 50,
                    borderRadius: 20,
                    padding: 10,
                    paddingHorizontal: 15,
                    width: '100%',
                    backgroundColor:
                      isDarkMode == 'true' ? '#999999' : '#f0f0f0',
                  }}
                  placeholder='Enter...'
                  value={CommentData}
                  onChange={(text) => setCommentData(text.target.value)}
                />
              </div>
              <button
                onClick={() => postCommentHandler()}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor: 'inherit',
                  border: 0,
                  cursor: 'pointer',
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  width={30}
                >
                  <path
                    fill={isDarkMode == 'true' ? '#ffde2e' : '#fc3c44'}
                    d='M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleMagazineContent;
